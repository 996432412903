import React from "react";
import { DriverAvatar } from "@src/common/components";
import { GetDriverCurrentLocationQuery } from "@api/graphql/generated/generated-types";
import { Marker } from "@urbica/react-map-gl";
import TextLocationStatus from "../../TextLocationStatus/TextLocationStatus";

export const DriverMarkerMap = ({
  DriverWithLocationData,
}: {
  DriverWithLocationData: GetDriverCurrentLocationQuery | undefined;
}) => {
  const lastReportedTime =
    DriverWithLocationData?.driverById.lastReportedLocation?.lastReportedTime;
  return (
    <Marker
      longitude={
        DriverWithLocationData?.driverById.lastReportedLocation?.lngLat.lng || 0
      }
      latitude={
        DriverWithLocationData?.driverById.lastReportedLocation?.lngLat.lat || 0
      }
      draggable={false}
    >
      <DriverAvatar
        driverPhoto={DriverWithLocationData?.driverById?.photoUrl || ""}
        driverName={DriverWithLocationData?.driverById?.firstName || ""}
        size={35}
      />
      <div className="left-12 top-0 bg-white p-2 rounded shadow-lg absolute">
        <TextLocationStatus lastReportedTime={lastReportedTime} />
      </div>
    </Marker>
  );
};
