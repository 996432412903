import React, { useEffect } from "react";
import { Button } from "@src/common/components";
import { useMarkShipmentAsClosedMutation } from "@api/graphql/generated/generated-types";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { ShipmentRow } from "../types";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";

type Props = {
  onClick: () => void;
  lastClosedShipmentIds: string[];
  setLastClosedShipmentIds: React.Dispatch<React.SetStateAction<string[]>>;
  row: ShipmentRow;
};

function MarkShipmentAsClosedButton({
  onClick,
  lastClosedShipmentIds,
  setLastClosedShipmentIds,
  row,
}: Props) {
  const { showToast } = useCourieStore();
  const { refetchGetShipments } = useShipmentsCoreDataContext();
  const [
    markShipmentAsClosed,
    {
      data: MarkShipmentAsClosedData,
      loading: MarkShipmentAsClosedLoading,
      error: MarkShipmentAsClosedError,
    },
  ] = useMarkShipmentAsClosedMutation();

  useEffect(() => {
    if (MarkShipmentAsClosedLoading === false) {
      setLastClosedShipmentIds([]);
    }
  }, [MarkShipmentAsClosedLoading]);

  useEffect(() => {
    if (MarkShipmentAsClosedError) {
      showErrorToast(MarkShipmentAsClosedError, showToast);
    }
  }, [MarkShipmentAsClosedError]);

  useEffect(() => {
    if (MarkShipmentAsClosedData && MarkShipmentAsClosedLoading === false) {
      showToast({
        message: "The order is closed successfully",
        type: "success",
      });
      refetchGetShipments();
    }
  }, [MarkShipmentAsClosedData, MarkShipmentAsClosedLoading]);

  if (row) {
    return (
      <Button
        size={"xs"}
        isProcessing={
          (MarkShipmentAsClosedLoading || false) &&
          lastClosedShipmentIds.includes(row.id)
        }
        onClick={() => {
          onClick();
          markShipmentAsClosed({
            variables: {
              shipmentId: row.id,
            },
          });
        }}
      >
        <div className="flex gap-1 truncate">
          <div>Close</div>
        </div>
      </Button>
    );
  }
  return null;
}

export default MarkShipmentAsClosedButton;
