import { Task, Stop, LngLat } from "@api/graphql/generated/generated-types";

export const extractLngLat = (item: Task | Stop): LngLat => {
  let lngLat: LngLat;

  if (item.__typename === "Task") {
    lngLat = item.stop.lngLat;
  } else {
    const stop = item as Stop;
    lngLat = stop.lngLat;
  }

  // Validate the longitude and latitude
  if (!isValidLngLat(lngLat)) {
    return { lng: 0, lat: 0 } as LngLat;
  }

  return lngLat;
};

// Utility function to check if the LngLat object is valid
export const isValidLngLat = (lngLat: LngLat): boolean => {
  return (
    typeof lngLat.lng === "number" &&
    !isNaN(lngLat.lng) &&
    typeof lngLat.lat === "number" &&
    !isNaN(lngLat.lat)
  );
};
