import { CalendarIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "flowbite-react";

export default function RecurringInstanceOrderIcon() {
  return (
    <Tooltip content="Recurring Scheduled Order">
      <CalendarIcon className="w-4 h-4" />
    </Tooltip>
  );
}
