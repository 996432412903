/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";
import { ShipmentRow } from "../types";

type ShipmentsTableContextType = {
  selectedShipmentRowToChangeRouting: ShipmentRow | null;
  setSelectedShipmentRowToChangeRouting: (row: ShipmentRow | null) => void;
};

export const ShipmentsTableContext =
  createContext<ShipmentsTableContextType | undefined>(undefined);

export const ShipmentsTableProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [
    selectedShipmentRowToChangeRouting,
    setSelectedShipmentRowToChangeRouting,
  ] = useState<ShipmentRow | null>(null);

  return (
    <ShipmentsTableContext.Provider
      value={{
        selectedShipmentRowToChangeRouting,
        setSelectedShipmentRowToChangeRouting,
      }}
    >
      {children}
    </ShipmentsTableContext.Provider>
  );
};

export const useShipmentsTableContext = () => {
  const context = useContext(ShipmentsTableContext);
  if (context === undefined) {
    throw new Error(
      "useShipmentsTableContext must be used within a ShipmentsTableProvider"
    );
  }
  return context;
};
