import { Button, Input, Label, Modal } from "@src/common/components";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ProofOfDeliveryModalSlides } from "./ProofOfDeliveryModal";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect } from "react";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { CommunicationTypeId } from "@api/graphql/generated/generated-types";

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  setCurrentSlide: (value: ProofOfDeliveryModalSlides) => void;
  sendCommunicationToEndCustomerMutation: any;
  selectedShipmentIdPod: string;
  isLoading: boolean;
};

const schema = yup.object().shape({
  emailAddress: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

export function SendPodEmailSlide({
  isModalOpen,
  onClose,
  setCurrentSlide,
  sendCommunicationToEndCustomerMutation,
  selectedShipmentIdPod,
  isLoading,
}: Props) {
  const {
    handleSubmit,
    watch,
    control,
    getValues,
    formState,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const emailAddress = watch("emailAddress");
  const { courierId } = useContext(AuthContext);

  const onSubmit = (data: any) => {
    sendCommunicationToEndCustomerMutation({
      variables: {
        courierId: courierId!,
        shipmentId: selectedShipmentIdPod,
        stopId: undefined,
        communicationTypeId: CommunicationTypeId.OrderClosed,
        emailRecipientsCommaSeparated: data.emailAddress,
      },
    });
  };

  useEffect(() => {
    if (!isModalOpen) {
      reset({
        emailAddress: undefined,
      });
      setCurrentSlide(ProofOfDeliveryModalSlides.VIEW_POD);
    }
  }, [isModalOpen]);

  return (
    <>
      <Modal.Body className="max-h-[36rem] overflow-y-auto">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid gap-2 space-y-2"
        >
          <div>
            <Label
              htmlFor="emailAddress"
              className="block text-sm font-medium text-gray-700"
            >
              Email Recipient
              {errors.emailAddress && (
                <span className="text-red-500 text-xs ml-2">
                  *Valid email address is required to send POD
                </span>
              )}
            </Label>
            <Controller
              control={control}
              name="emailAddress"
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={(event) =>
                    field.onChange(event.currentTarget.value)
                  }
                  onBlur={field.onBlur}
                  type="text"
                  placeholder="alice@example.com"
                  error={errors.emailAddress ? true : false}
                />
              )}
            />
          </div>
          <div className="flex flex-row justify-end mt-3 gap-2">
            <Button color="light" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isProcessing={isLoading}
              type="submit"
              disabled={emailAddress === undefined || emailAddress === ""}
            >
              <div className="flex gap-2 items-center">
                <CheckCircleIcon width={16} height={16} />
                Confirm & Send
              </div>
            </Button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}
