import React, { useContext } from "react";
import {
  OrderAttachment,
  useCreateOrderAttachmentsMutation,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useShipmentSelectionContext } from "../../context/ShipmentSelectionContext";
import { FileUploader } from "baseui/file-uploader";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";

interface FileUploadProps {
  setUploadedFile: React.Dispatch<React.SetStateAction<OrderAttachment[]>>;
  uploadedFile: OrderAttachment[];
  onSuccessUpload?: () => void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  setUploadedFile,
  uploadedFile,
  onSuccessUpload,
}) => {
  const { showToast } = useCourieStore();
  const { courierId } = useContext(AuthContext);
  const { shipmentSelectedForAction } = useShipmentSelectionContext();
  const [createOrderAttachmentsMutation, { loading, error }] =
    useCreateOrderAttachmentsMutation();

  const handleFileUpload = async (acceptedFiles: File[]) => {
    if (
      acceptedFiles.length === 0 ||
      !courierId ||
      !shipmentSelectedForAction?.order.id
    ) {
      return;
    }

    const file = acceptedFiles[0];

    createOrderAttachmentsMutation({
      variables: {
        courierId,
        orderAttachmentUploads: {
          orderId: shipmentSelectedForAction.order.id,
          file,
        },
      },
    })
      .then((result) => {
        if (result.data?.createOrderAttachments) {
          setUploadedFile([
            ...uploadedFile,
            ...result.data.createOrderAttachments,
          ]);
          onSuccessUpload && onSuccessUpload();
        }
      })
      .catch((err) => {
        showErrorToast(err, showToast);
      });
  };

  return (
    <div>
      <FileUploader
        multiple={false}
        onDrop={(acceptedFiles, rejectedFiles) => {
          handleFileUpload(acceptedFiles);
        }}
        onRetry={() => {
          // Handle retry logic if needed
        }}
        errorMessage={
          error ? "Error uploading file. Please try again." : undefined
        }
        progressMessage={loading ? "Uploading..." : undefined}
        progressAmount={loading ? Infinity : undefined}
      />
    </div>
  );
};

export default FileUpload;
