import { Input } from "@src/common/components";
import React from "react";
import { useShipmentFiltersContext } from "../context/ShipmentFiltersContext";

type Props = {};

function SearchShipmentsInput({}: Props) {
  const { searchTerm, setSearchTerm } = useShipmentFiltersContext();

  return (
    <Input
      placeholder="Search"
      size="mini"
      value={searchTerm}
      onChange={(event) => setSearchTerm(event.currentTarget.value)}
      clearable
      overrides={{
        Root: {
          style: ({ $theme, $isFocused, $isPseudoFocused }) => ({
            backgroundColor:
              $isFocused || $isPseudoFocused
                ? $theme.colors.backgroundAlt
                : "transparent",
            borderColor: $theme.colors.border,
            borderWidth: "1px",
            borderRadius: $theme.borders.radius400,
            height: "27px",
            boxShadow: "none",
            ":hover": {
              backgroundColor: $theme.colors.backgroundSecondary,
              borderColor: $theme.colors.borderHover,
            },
          }),
        },
        ClearIcon: {
          style: {
            width: "24px",
            height: "24px",
          },
          props: {
            size: 24,
          },
        },
        ClearIconContainer: {
          style: {
            backgroundColor: "transparent",
          },
        },
      }}
    />
  );
}

export default SearchShipmentsInput;
