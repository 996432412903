import {
  BeakerIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import CourieButton from "@src/common/components/Button/Button";
import React from "react";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";
import { RoutingJobStatus } from "@api/graphql/generated/generated-types";
import { useRouter } from "next/router";

type Props = {};

function RoutingJobsStatusButtons({}: Props) {
  const { routingJobsData, routingJobsLoading } = useShipmentsCoreDataContext();
  const router = useRouter();

  if (routingJobsLoading || !routingJobsData) return null;

  const lastJob = routingJobsData.routingJobs.edges?.[0]?.node;

  if (!lastJob) return null;

  const isRelevantStatus = [
    RoutingJobStatus.Pending,
    RoutingJobStatus.Processing,
    RoutingJobStatus.Ready,
    RoutingJobStatus.OptimizationFailed,
  ].includes(lastJob.status);

  if (!isRelevantStatus) return null;

  const handleClickReady = () => {
    router.push(`/routingjob/${lastJob.id}`);
  };

  return (
    <div className="flex gap-2">
      {lastJob.status === RoutingJobStatus.OptimizationFailed && (
        <span className="text-red-500 text-sm flex items-center truncate">
          <BeakerIcon className="h-4 w-4 mr-1" />
          Optimization Failed
        </span>
      )}
      {lastJob.status === RoutingJobStatus.Processing && (
        <CourieButton
          size="xs"
          color="gray"
          className="animate-pulse"
          onClick={handleClickReady}
        >
          <BeakerIcon className="h-4 w-4 mr-1" />
          Optimization Processing
        </CourieButton>
      )}
      {lastJob.status === RoutingJobStatus.Pending && (
        <CourieButton
          size="xs"
          color="gray"
          className="animate-pulse"
          onClick={handleClickReady}
        >
          <BeakerIcon className="h-4 w-4 mr-1" />
          Optimization Pending
        </CourieButton>
      )}
      {lastJob.status === RoutingJobStatus.Ready && (
        <CourieButton size="xs" color="green" onClick={handleClickReady}>
          <DocumentMagnifyingGlassIcon className="h-4 w-4 mr-1" />
          Review Routes
        </CourieButton>
      )}
    </div>
  );
}

export default RoutingJobsStatusButtons;
