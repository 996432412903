/* eslint-disable react-hooks/exhaustive-deps */
import {
  BaseModalBody,
  BaseModalFooter,
  BaseModalHeader,
} from "@src/common/components";
import CourieButton from "@src/common/components/Button/Button";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import EditOrderForm from "../editOrderModal/EditOrderForm";
import { useShipmentSelectionContext } from "../../context/ShipmentSelectionContext";
import useEditOrderFormSubmit from "../../hooks/useEditOrderFormSubmit";
import { EditOrderModalSlides } from "./EditOrderModal";

type Props = {
  onConfirm: () => void;
  setIsModalOpen: (value: boolean) => void;
  shouldChangeRoute: boolean;
  setCurrentSlide: React.Dispatch<React.SetStateAction<EditOrderModalSlides>>;
  selectedDuration: string;
  setSelectedDuration: React.Dispatch<React.SetStateAction<string>>;
  onStopDateChange: () => void;
  isDifferentStopDate: boolean;
};

const EditOrderSlide = ({
  onConfirm,
  setIsModalOpen,
  shouldChangeRoute,
  setCurrentSlide,
  selectedDuration,
  setSelectedDuration,
  onStopDateChange,
  isDifferentStopDate,
}: Props) => {
  const { handleSubmit, reset } = useFormContext();
  const { shipmentSelectedForAction } = useShipmentSelectionContext();
  const { onSubmit, loading, stopIdsToDelete, setStopIdsToDelete } =
    useEditOrderFormSubmit({
      onConfirm,
      reset,
      setCurrentSlide,
      isDifferentStopDate,
    });
  const [isAllStopsHaveAddress, setIsAllStopsHaveAddress] = useState(false);

  return (
    <>
      <BaseModalHeader>
        Edit order #{shipmentSelectedForAction?.order.displayId}
      </BaseModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BaseModalBody
          style={{ minWidth: 1200, height: "70vh", overflow: "scroll" }}
        >
          <EditOrderForm
            stopIdsToDelete={stopIdsToDelete}
            setStopIdsToDelete={setStopIdsToDelete}
            setIsAllStopsHaveAddress={setIsAllStopsHaveAddress}
            selectedDuration={selectedDuration}
            setSelectedDuration={setSelectedDuration}
            onStopDateChange={onStopDateChange}
          />
        </BaseModalBody>
        <BaseModalFooter className=" col-span-2 flex justify-between">
          <CourieButton
            color={"secondary"}
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </CourieButton>
          <CourieButton
            isProcessing={loading}
            type="submit"
            disabled={!isAllStopsHaveAddress || loading}
          >
            {shouldChangeRoute ? "Submit & Change Route" : "Submit"}
          </CourieButton>
        </BaseModalFooter>
      </form>
    </>
  );
};

export default EditOrderSlide;
