import React from "react";

interface IconContainerProps {
  children: React.ReactNode;
  size?: number;
}

const IconContainer: React.FC<IconContainerProps> = ({
  children,
  size = 36,
}) => (
  <div
    style={{
      backgroundColor: "#FFFFFF",
      borderRadius: "50%",
      boxShadow: "0 0 6px rgba(0, 0, 0, 0.2)",
      border: "1px solid #000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: `${size}px`,
      height: `${size}px`,
    }}
  >
    {children}
  </div>
);

export default IconContainer;
