import React from "react";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";
import { Paginator, Select } from "@src/common/components";

type Props = {};

function ShipmentsTableFooter({}: Props) {
  const { shipmentsApiResponse, currentPage, setCurrentPage, limit, setLimit } =
    useShipmentsCoreDataContext();

  const pageInfo = shipmentsApiResponse?.shipments?.pageInfo;

  const options = [
    { id: "25", label: "25" },
    { id: "50", label: "50" },
    { id: "100", label: "100" },
    { id: "200", label: "200" },
  ];

  const getPackageTypeSelectValue = (value: string) => {
    if (!value) return undefined;
    const selectedOption = options.find((option) => option.id === value);
    return [
      {
        id: selectedOption?.id,
        label: selectedOption?.label,
      },
    ];
  };

  return (
    <div className="px-4 py-0 flex justify-between items-center">
      <div className="flex py-1">
        <label className="text-xs font-normal text-gray-500 dark:text-gray-400 my-auto mr-1">
          Show:
        </label>
        <Select
          size="mini"
          backspaceRemoves={false}
          clearable={false}
          deleteRemoves={false}
          escapeClearsValue={false}
          options={options}
          value={getPackageTypeSelectValue(limit.id)}
          searchable={false}
          onChange={(params) => {
            setLimit(params.option as any);
          }}
        />
      </div>
      <div className="flex items-center">
        {pageInfo && (
          <Paginator
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            pageInfo={pageInfo}
            previousLabel="Prev"
            nextLabel="Next"
            className="p-0"
          />
        )}
      </div>
    </div>
  );
}

export default ShipmentsTableFooter;
