import classNames from "classnames";
import moment from "moment";
import React from "react";

type Props = {
  listStopDates: string[];
  selectedStopDate: string;
  shipmentDate?: string | null;
  onClickStopDate?: (stopDate: string) => void;
};

function StopDateListTimeline({
  listStopDates,
  selectedStopDate,
  shipmentDate,
  onClickStopDate,
}: Props) {
  if (listStopDates.length === 0 && !selectedStopDate && shipmentDate) {
    return <span className="text-xs mr-3 underline">{shipmentDate}</span>;
  }
  return (
    <div>
      {listStopDates && listStopDates.length > 1 ? (
        listStopDates.map((stopDate) => (
          <span
            key={stopDate}
            className={classNames({
              "text-xs mr-3": true,
              "font-bold underline": selectedStopDate === stopDate,
            })}
            onClick={() => onClickStopDate && onClickStopDate(stopDate)}
          >
            {moment(stopDate).format("MMM DD, YYYY")}
          </span>
        ))
      ) : selectedStopDate === shipmentDate ? (
        <span className="text-xs mr-3 underline">
          {moment(shipmentDate).format("MMM DD, YYYY")}
        </span>
      ) : (
        <span className="text-xs mr-3">
          {moment(selectedStopDate).format("MMM DD, YYYY")}
        </span>
      )}
    </div>
  );
}

export default StopDateListTimeline;
