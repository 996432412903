const SelectableCard = ({
  title,
  description,
  value,
  selectedValue,
  onChange,
  disabled = false,
}) => (
  <div
    className={`p-4 border rounded-md ${
      disabled
        ? "cursor-not-allowed opacity-50"
        : "cursor-pointer hover:shadow-md"
    } max-w-xs ${
      value === selectedValue && !disabled
        ? "bg-blue-100 border-blue-500"
        : "bg-white border-gray-300"
    }`}
    onClick={() => !disabled && onChange(value)}
  >
    <h3 className={`font-semibold ${disabled ? "text-gray-500" : ""}`}>
      {title}
    </h3>
    <p
      className={`text-sm ${disabled ? "text-gray-400" : "text-gray-600"} mt-2`}
    >
      {description}
    </p>
  </div>
);

export default SelectableCard;
