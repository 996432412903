import React, { useContext } from "react";
import WarningConfirmationModal from "@src/common/components/Modal/WarningConfirmationModal";
import {
  ShipmentStatus,
  useBulkUnassignDriversFromShipmentMutation,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import { useShipmentsCoreDataContext } from "@src/shipments/context/ShipmentsCoreData";

interface BulkUnassignDriversModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  setIsModalOpen: (open: boolean) => void;
}

const BulkUnassignDriversModal: React.FC<BulkUnassignDriversModalProps> = ({
  isModalOpen,
  onClose,
  onSuccess,
  setIsModalOpen,
}) => {
  const { courierId } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  const [bulkUnassignDriversFromShipmentMutation, { data, loading, error }] =
    useBulkUnassignDriversFromShipmentMutation();

  const { checkedShipments } = useShipmentSelectionContext();

  const handleUnassignDrivers = () => {
    if (!courierId) return;
    bulkUnassignDriversFromShipmentMutation({
      variables: {
        courierId,
        shipmentIds: checkedShipments,
      },
    })
      .then(() => {
        showToast({
          type: "success",
          message: "Drivers unassigned successfully",
        });
        onSuccess();
      })
      .catch((error) => {
        showErrorToast(error, showToast);
      });
  };
  return (
    <WarningConfirmationModal
      title={`Unassign Drivers from ${checkedShipments.length} shipments`}
      textBody={`Are you sure you want to unassign drivers from the selected ${checkedShipments.length} shipments?`}
      cancelButtonText="Cancel"
      primaryButtonText="Unassign"
      isModalOpen={isModalOpen}
      onCancel={onClose}
      onConfirm={handleUnassignDrivers}
      setIsModalOpen={setIsModalOpen}
      loading={loading}
    />
  );
};

export default BulkUnassignDriversModal;
