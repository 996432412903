import {
  Location,
  OrderingAlgorithmType,
  Stop,
  Task,
} from "@api/graphql/generated/generated-types";
import React, { createContext, useContext, ReactNode, useState } from "react";
import { useLocationIds } from "../hooks/useLocationIds";

interface DriverRouteViewSelectionContextType {
  hoveredTask: Task | Stop | undefined;
  setHoveredTask: React.Dispatch<React.SetStateAction<Task | Stop | undefined>>;
  hoverTaskLabel: number | undefined;
  setHoverTaskLabel: React.Dispatch<React.SetStateAction<number | undefined>>;
  handleAddressChange: (
    address: google.maps.places.AutocompletePrediction | undefined,
    type: "startLocation" | "endLocation"
  ) => void;
  startLocation: Location | undefined;
  endLocation: Location | undefined;
  selectedOrderingAlgorithmType: OrderingAlgorithmType;
  setSelectedOrderingAlgorithmType: React.Dispatch<
    React.SetStateAction<OrderingAlgorithmType>
  >;
  selectedBaseTime: Date;
  setSelectedBaseTime: React.Dispatch<React.SetStateAction<Date>>;
}

const DriverRouteViewSelectionContext =
  createContext<DriverRouteViewSelectionContextType | undefined>(undefined);

interface DriverRouteViewSelectionProviderProps {
  children: ReactNode;
}

export const DriverRouteViewSelectionProvider: React.FC<DriverRouteViewSelectionProviderProps> =
  ({ children }) => {
    const [hoveredTask, setHoveredTask] =
      useState<Task | Stop | undefined>(undefined);
    const [hoverTaskLabel, setHoverTaskLabel] =
      useState<number | undefined>(undefined);
    const [selectedOrderingAlgorithmType, setSelectedOrderingAlgorithmType] =
      useState(OrderingAlgorithmType.Manual);
    const [selectedBaseTime, setSelectedBaseTime] = useState<Date>(new Date());

    const { handleAddressChange, startLocation, endLocation } =
      useLocationIds();

    return (
      <DriverRouteViewSelectionContext.Provider
        value={{
          hoveredTask,
          setHoveredTask,
          hoverTaskLabel,
          setHoverTaskLabel,
          handleAddressChange,
          startLocation,
          endLocation,
          selectedOrderingAlgorithmType,
          setSelectedOrderingAlgorithmType,
          selectedBaseTime,
          setSelectedBaseTime,
        }}
      >
        {children}
      </DriverRouteViewSelectionContext.Provider>
    );
  };

export const useDriverRouteViewSelectionProvider = () => {
  const context = useContext(DriverRouteViewSelectionContext);
  if (context === undefined) {
    throw new Error(
      "useDriverRouteViewSelectionProvider must be used within a DriverRouteViewSelectionProvider"
    );
  }
  return context;
};
