import { Stop } from "@api/graphql/generated/generated-types";

export function getUniqueStopDates(routeDetails: any[]): any[] | undefined {
  const uniqueStopDates = Array.from(
    new Set(
      routeDetails
        .map((routeDetail) => {
          if (routeDetail.__typename === "Task" && routeDetail.stop.stopDate) {
            return routeDetail.stop.stopDate;
          } else if (
            routeDetail.__typename === "Stop" &&
            routeDetail.stopDate
          ) {
            return routeDetail.stopDate;
          }
          return undefined;
        })
        .filter(Boolean)
    )
  ).sort();

  return uniqueStopDates.length > 0 ? uniqueStopDates : undefined;
}

export const getStopIds = (routeDetails: Stop[] | undefined) => {
  if (!routeDetails) {
    return [];
  }
  return routeDetails.map((routeDetail) => {
    return routeDetail.id;
  });
};
