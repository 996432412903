import {
  Driver,
  DriverEdge,
  TaskStatus,
  useFetchDriverTasksByDateAndStatusLazyQuery,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { DRIVER_API_LIMIT } from "@src/common/constants/apiConstants";
import { sortDriversByName } from "@src/common/lib/driverSorters";
import { makeDriverOptions } from "@src/shipments/utils/makeDriverOptions";
import moment from "moment";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { DriverOption } from "../DriverFilterSelector";
import { DriverNameCell } from "@src/common/components/DriverNameCell/DriverNameCell";
import { Loading } from "@src/common/components";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { XCircleIcon } from "@heroicons/react/20/solid";

type Props = {
  setSelectedDriver: React.Dispatch<React.SetStateAction<Driver | undefined>>;
  selectedDriver: Driver | undefined;
  onDriverSelect?: () => void;
  hideDriverId?: string;
};

function DriverSelectorList({
  selectedDriver,
  setSelectedDriver,
  onDriverSelect,
  hideDriverId,
}: Props) {
  const { courierId } = useContext(AuthContext);
  const [drivers, setDrivers] = useState<DriverOption[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [
    FetchDriverTasksByDateAndStatus,
    {
      loading: FetchDriverTasksByDateAndStatusLoading,
      error: FetchDriverTasksByDateAndStatusError,
    },
  ] = useFetchDriverTasksByDateAndStatusLazyQuery();

  // Debounce search term update
  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((term: string) => {
        setIsSearching(false);
        setDebouncedSearchTerm(term);
      }, 300),
    []
  );

  // Update search term with debounce
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsSearching(true);
    debouncedSetSearchTerm(e.target.value);
  };

  // Clear search
  const handleClearSearch = () => {
    setSearchTerm("");
    setDebouncedSearchTerm("");
    setIsSearching(false);
  };

  useEffect(() => {
    if (!courierId) {
      return;
    }
    FetchDriverTasksByDateAndStatus({
      variables: {
        courierId,
        date: moment().format("YYYY-MM-DD"),
        statusFilter: [TaskStatus.Created, TaskStatus.Started],
        limit: DRIVER_API_LIMIT,
      },
    }).then((res) => {
      const fetchedDrivers = makeDriverOptions(
        (res.data?.drivers?.edges as DriverEdge[]) || []
      );
      const sortedDrivers = sortDriversByName(fetchedDrivers);
      const filteredDrivers = sortedDrivers.filter(
        (driver) => driver.id !== hideDriverId
      );
      setDrivers(filteredDrivers);
    });
  }, [courierId]);

  const filteredDrivers = useMemo(
    () =>
      drivers.filter((driver) =>
        driver.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      ),
    [drivers, debouncedSearchTerm]
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Search drivers..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 pr-8 w-full border border-gray-300 rounded-md"
        />
        {searchTerm && (
          <XCircleIcon
            className="absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 cursor-pointer"
            onClick={handleClearSearch}
          />
        )}
      </div>
      <div className="text-sm text-gray-500">
        Showing {filteredDrivers.length} of {drivers.length} drivers
      </div>
      <Loading
        loading={FetchDriverTasksByDateAndStatusLoading || isSearching}
        text={isSearching ? "Searching..." : "Loading drivers"}
        className="max-h-96 overflow-auto flex flex-col gap-2"
      >
        {filteredDrivers.length > 0 ? (
          filteredDrivers.map((driver) => (
            <div
              key={driver.id}
              className={classNames({
                "p-1 rounded-md hover:bg-slate-100 hover:cursor-pointer": true,
                "bg-primary-100": driver.driver?.id === selectedDriver?.id,
              })}
              onClick={() => {
                setSelectedDriver(driver.driver as Driver | undefined);
                onDriverSelect && onDriverSelect();
              }}
            >
              <DriverNameCell
                name={driver.name}
                avatarUrl={driver.url}
                isAvailable={driver.isActive}
                driver={driver.driver as Driver | undefined}
                highlightTerm={debouncedSearchTerm}
              />
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 py-4">No drivers found</div>
        )}
      </Loading>
    </div>
  );
}

export default DriverSelectorList;
