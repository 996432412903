import { DispatchCandidateSortingType } from "@src/shipments/types";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { useLocalStorage } from "usehooks-ts";
import { DispatchCandidateView } from "../DispatchCandidatesWidget";

interface CandidatesContextProps {
  candidates: DispatchCandidateView[];
  setCandidates: React.Dispatch<React.SetStateAction<DispatchCandidateView[]>>;
  unavailableCandidates: DispatchCandidateView[];
  setUnavailableCandidates: React.Dispatch<
    React.SetStateAction<DispatchCandidateView[]>
  >;
  specializedCandidates: DispatchCandidateView[];
  setSpecializedCandidates: React.Dispatch<
    React.SetStateAction<DispatchCandidateView[]>
  >;
  dispatchCandidatesSortingType: DispatchCandidateSortingType;
  setDispatchCandidatesSortingType: React.Dispatch<
    React.SetStateAction<DispatchCandidateSortingType>
  >;
}

const CandidatesContext =
  createContext<CandidatesContextProps | undefined>(undefined);

export const CandidatesProvider = ({ children }: { children: ReactNode }) => {
  const [candidates, setCandidates] = useState<DispatchCandidateView[]>([]);
  const [unavailableCandidates, setUnavailableCandidates] = useState<
    DispatchCandidateView[]
  >([]);
  const [specializedCandidates, setSpecializedCandidates] = useState<
    DispatchCandidateView[]
  >([]);
  const [dispatchCandidatesSortingType, setDispatchCandidatesSortingType] =
    useLocalStorage<DispatchCandidateSortingType>(
      "MonitorPageDispatchCandidateSortingType",
      DispatchCandidateSortingType.LEAST_DEVIATION
    );

  return (
    <CandidatesContext.Provider
      value={{
        candidates,
        setCandidates,
        unavailableCandidates,
        setUnavailableCandidates,
        specializedCandidates,
        setSpecializedCandidates,
        dispatchCandidatesSortingType,
        setDispatchCandidatesSortingType,
      }}
    >
      {children}
    </CandidatesContext.Provider>
  );
};

export const useCandidates = () => {
  const context = useContext(CandidatesContext);
  if (context === undefined) {
    throw new Error("useCandidates must be used within a CandidatesProvider");
  }
  return context;
};
