import React from "react";
import AddressInput from "@src/orders/create/components/AddressInput";
import { TimePicker } from "baseui/timepicker";
import { OrderingAlgorithmType } from "@api/graphql/generated/generated-types";
import { useDriverRouteViewSelectionProvider } from "../contexts/DriverRouteViewSelectionProvider";
import CourieButton from "../../Button/Button";

interface RoutePanelProps {
  handleOrderingAlgorithmChange: (
    index: number,
    date?: Date,
    startLocationId?: string,
    endLocationId?: string
  ) => void;
  updateEtaForRouteDetails: (routeDetails: any, date: Date) => void;
  routeDetails: any;
  tabOrder: OrderingAlgorithmType[];
}

const RouteSettingsPanel: React.FC<RoutePanelProps> = ({
  handleOrderingAlgorithmChange,
  updateEtaForRouteDetails,
  routeDetails,
  tabOrder,
}) => {
  const {
    handleAddressChange,
    startLocation,
    endLocation,
    selectedOrderingAlgorithmType,
    selectedBaseTime,
    setSelectedBaseTime,
  } = useDriverRouteViewSelectionProvider();

  const applyLocationIds = () => {
    const selectedOrderingAlgorithmTypeIndex = tabOrder.findIndex(
      (type) => type === selectedOrderingAlgorithmType
    );
    handleOrderingAlgorithmChange(
      selectedOrderingAlgorithmTypeIndex,
      selectedBaseTime,
      startLocation?.id,
      endLocation?.id
    );
  };

  return (
    <div
      className={`absolute z-10 bottom-0 right-0 w-full flex ${
        selectedOrderingAlgorithmType === OrderingAlgorithmType.Reoptimized
          ? "backdrop-blur-lg"
          : ""
      }`}
    >
      {selectedOrderingAlgorithmType === OrderingAlgorithmType.Reoptimized ? (
        <>
          <div className="w-4/12 p-2">
            <span className="text-xs">Start Location</span>
            <AddressInput
              handleAddressChange={(address) =>
                handleAddressChange(address, "startLocation")
              }
              value={
                startLocation?.streetAddress &&
                `${startLocation?.streetAddress}, ${startLocation?.city}, ${startLocation?.state}`
              }
              size="mini"
            />
          </div>
          <div className="w-4/12 p-2">
            <span className="text-xs">End Location</span>
            <AddressInput
              handleAddressChange={(address) =>
                handleAddressChange(address, "endLocation")
              }
              value={
                endLocation?.streetAddress &&
                `${endLocation?.streetAddress}, ${endLocation?.city}, ${endLocation?.state}`
              }
              size="mini"
            />
          </div>
          <div className="w-2/12 items-end pb-2 pl-2 flex justify-start">
            <CourieButton
              size={"xs"}
              color={"gray"}
              onClick={() => applyLocationIds()}
            >
              Apply
            </CourieButton>
          </div>
        </>
      ) : (
        <div className="w-10/12" />
      )}
      <div className="w-2/12 p-2 backdrop-blur-lg">
        <span className="text-xs">Start Time</span>
        <TimePicker
          size="mini"
          value={selectedBaseTime}
          nullable={true}
          onChange={(date) => {
            if (date) {
              setSelectedBaseTime(date);
              const selectedOrderingAlgorithmTypeIndex = tabOrder.findIndex(
                (type) => type === selectedOrderingAlgorithmType
              );
              handleOrderingAlgorithmChange(
                selectedOrderingAlgorithmTypeIndex,
                date,
                startLocation?.id,
                endLocation?.id
              );
              routeDetails && updateEtaForRouteDetails(routeDetails, date);
            }
          }}
        />
      </div>
    </div>
  );
};

export default RouteSettingsPanel;
