import React, { useEffect } from "react";
import { Button } from "@src/common/components";
import { useUncloseShipmentMutation } from "@api/graphql/generated/generated-types";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { ShipmentRow } from "../types";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";

type Props = {
  onClick: () => void;
  lastUnclosedShipmentIds: string[];
  setLastUnclosedShipmentIds: React.Dispatch<React.SetStateAction<string[]>>;
  row: ShipmentRow;
};

function UncloseShipmentButton({
  onClick,
  lastUnclosedShipmentIds,
  setLastUnclosedShipmentIds,
  row,
}: Props) {
  const { showToast } = useCourieStore();
  const { refetchGetShipments } = useShipmentsCoreDataContext();
  const [
    uncloseShipment,
    {
      data: UncloseShipmentData,
      loading: UncloseShipmentLoading,
      error: UncloseShipmentError,
    },
  ] = useUncloseShipmentMutation();

  useEffect(() => {
    if (UncloseShipmentLoading === false) {
      setLastUnclosedShipmentIds([]);
    }
  }, [UncloseShipmentLoading]);

  useEffect(() => {
    if (UncloseShipmentError) {
      showErrorToast(UncloseShipmentError, showToast);
    }
  }, [UncloseShipmentError]);

  useEffect(() => {
    if (UncloseShipmentData && UncloseShipmentLoading === false) {
      showToast({
        message: "The order is unclosed successfully",
        type: "success",
      });
      refetchGetShipments();
    }
  }, [UncloseShipmentData, UncloseShipmentLoading]);

  if (row) {
    return (
      <Button
        size={"xs"}
        color={"light"}
        isProcessing={
          (UncloseShipmentLoading || false) &&
          lastUnclosedShipmentIds.includes(row.id)
        }
        onClick={() => {
          onClick();
          uncloseShipment({
            variables: {
              shipmentId: row.id,
            },
          });
        }}
      >
        <div className="flex gap-1 truncate">
          <div>Unclose</div>
        </div>
      </Button>
    );
  }
  return null;
}

export default UncloseShipmentButton;
