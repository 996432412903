import { Button, Modal } from "@src/common/components";
import { ArtifactsAtStop } from "@src/shipments/types";
import { ArtifactsView } from "./ArtifactsView";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ProofOfDeliveryModalSlides } from "./ProofOfDeliveryModal";

type Props = {
  artifactsAtStops: ArtifactsAtStop[];
  onClose: () => void;
  setCurrentSlide: (value: ProofOfDeliveryModalSlides) => void;
};

export function PodViewSlide({
  artifactsAtStops,
  onClose,
  setCurrentSlide,
}: Props) {
  return (
    <>
      <Modal.Body className="max-h-[36rem] overflow-y-auto">
        {artifactsAtStops && (
          <ArtifactsView artifactsAtStops={artifactsAtStops} />
        )}
        {!artifactsAtStops ||
          (artifactsAtStops.length === 0 && (
            <div className="text-orange-400 truncate">POD Missing</div>
          ))}
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <Button color="light" onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={() => {
            setCurrentSlide(ProofOfDeliveryModalSlides.SEND_EMAIL);
          }}
        >
          <div className="flex gap-2 items-center">
            <EnvelopeIcon width={16} height={16} />
            Send Email
          </div>
        </Button>
      </Modal.Footer>
    </>
  );
}
