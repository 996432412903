import { ShipmentsSortField } from "@api/graphql/generated/generated-types";

export interface TableColumnType {
  title: string;
  dataIndex: string | undefined;
  key: string;
  sortFields?: string[];
}

export const SHIPMENT_TABLE_COLUMNS: TableColumnType[] = [
  {
    title: "ID",
    dataIndex: "order",
    key: "order",
    sortFields: [ShipmentsSortField.OrderDisplayId],
  },
  {
    title: "Customer",
    dataIndex: "customers",
    key: "customers",
    sortFields: [ShipmentsSortField.EndCustomerName],
  },
  {
    title: "Origin",
    dataIndex: "pickup",
    key: "pickup",
    sortFields: [
      ShipmentsSortField.OriginDispatchZone,
      ShipmentsSortField.PickUpCompanyName,
    ],
  },
  {
    title: "Destination",
    dataIndex: "dropoff",
    key: "dropoff",
    sortFields: [
      ShipmentsSortField.DestinationDispatchZone,
      ShipmentsSortField.DropOffCompanyName,
    ],
  },
  {
    title: "Ready",
    dataIndex: "ready",
    key: "ready",
    sortFields: [ShipmentsSortField.PickUpReadyTime],
  },
  {
    title: "Deliver By",
    dataIndex: "deliverBy",
    key: "deliverBy",
    sortFields: [ShipmentsSortField.DropOffDueTime],
  },
  {
    title: "Driver",
    dataIndex: "driverName",
    key: "driverName",
    sortFields: [ShipmentsSortField.DriverName],
  },
  {
    title: "Package",
    dataIndex: "",
    key: "",
  },
  {
    title: "",
    dataIndex: "menu",
    key: "menu",
  },
];
