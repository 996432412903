import { Driver, DriverWorkType } from "@api/graphql/generated/generated-types";
import { DispatchCandidateSortingType } from "@src/shipments/types";

export type DriverGroup = {
  onlineRegular: Driver[];
  offlineRegular: Driver[];
  specialized: Driver[];
};

export const sortAndGroupDrivers = (
  drivers: Driver[],
  sorting: DispatchCandidateSortingType
): DriverGroup => {
  const getSortFunction = (sorting: DispatchCandidateSortingType) => {
    if (sorting === DispatchCandidateSortingType.LEAST_TASKS) {
      return (a: Driver, b: Driver) => {
        const aTasksLength = a?.tasks?.length || 0;
        const bTasksLength = b?.tasks?.length || 0;

        if (aTasksLength === bTasksLength) {
          // If task lengths are equal, sort by first name as secondary criteria
          return (a?.firstName || "").localeCompare(b?.firstName || "");
        }
        return aTasksLength - bTasksLength;
      };
    }

    // Default alphabetical sorting
    return (a: Driver, b: Driver) => {
      return (a?.firstName || "").localeCompare(b?.firstName || "");
    };
  };

  const sortFunction = getSortFunction(sorting);

  const onlineRegular = drivers
    .filter(
      (driver) =>
        driver?.workType === DriverWorkType.Regular && driver?.isActive
    )
    .sort(sortFunction);

  const offlineRegular = drivers
    .filter(
      (driver) =>
        driver?.workType === DriverWorkType.Regular && !driver?.isActive
    )
    .sort(sortFunction);

  const specialized = drivers
    .filter((driver) => driver?.workType === DriverWorkType.Specialized)
    .sort(sortFunction);

  return {
    onlineRegular,
    offlineRegular,
    specialized,
  };
};
