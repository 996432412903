import { Stop, ShipmentStopType } from "@api/graphql/generated/generated-types";
import { StopTypeBadge } from "../DriverTask/DriverTask";
import { chooseIconForStopType } from "@src/shipments/utils/SortableListFormatters";
import {
  getSubtitle,
  renderShipmentStatus,
} from "@src/common/lib/shipmentStatus";

interface StopCardProps {
  stop: Stop;
}

const StopCard = ({ stop }: StopCardProps) => {
  const stopTypeBadgeColor = stop.type
    ? {
        [ShipmentStopType.DropOff]: "purple",
        [ShipmentStopType.Return]: "teal",
      }[stop.type] || "gray"
    : "gray";

  const StopTypeIcon = stop.type ? chooseIconForStopType(stop.type) : null;

  const title = stop?.dispatchZone || stop?.streetAddress || stop?.city || "";

  return (
    <div className="flex flex-col gap-1 p-2 bg-blue-50 rounded ml-2 w-full">
      <div className="text-sm text-gray-500">{title}</div>
      <div className="text-sm">
        <span className="truncate text-xs text-slate-600 col-span-2">
          {getSubtitle({
            dispatchZone: stop?.dispatchZone || "",
            streetAddress: stop?.streetAddress || "",
            companyName: stop?.companyName || "",
            city: stop?.city || "",
          })}
        </span>
      </div>
      <div className="flex justify-between items-center">
        <StopTypeBadge
          stopType={stop.type}
          color={stopTypeBadgeColor}
          Icon={StopTypeIcon}
        />
      </div>
    </div>
  );
};

export default StopCard;
