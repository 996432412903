import { RoutingJobStatus } from "@api/graphql/generated/generated-types";
import {
  XCircleIcon,
  ExclamationCircleIcon,
  BeakerIcon,
} from "@heroicons/react/24/outline";

export const getStatusMessage = (status: RoutingJobStatus) => {
  switch (status) {
    case RoutingJobStatus.Cancelled:
      return {
        icon: <XCircleIcon className="h-16 w-16 text-red-500 mb-4" />,
        message: "Route Optimization has been canceled.",
        subtext: "",
      };
    case RoutingJobStatus.OptimizationFailed:
      return {
        icon: <ExclamationCircleIcon className="h-16 w-16 text-red-500 mb-4" />,
        message: "Route optimization failed.",
        subtext: "Please review and try again.",
      };
    case RoutingJobStatus.Pending:
    case RoutingJobStatus.Processing:
      return {
        icon: (
          <BeakerIcon className="h-16 w-16 text-gray-500 mb-4 animate-bounce" />
        ),
        message: `Routing Optimization is currently processing.`,
        subtext: "",
      };
    default:
      return null;
  }
};
