/* eslint-disable react-hooks/exhaustive-deps */
import Head from "next/head";
import { Tooltip } from "@src/common/components";
import React, { useEffect, useState } from "react";
import ShipmentsTable from "@src/shipments/components/ShipmentsTable";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import { useDispatchPreviewContext } from "@src/shipments/context/DispatchPreviewContext";
import { useCourieStore } from "@src/common/lib/store";
import { Sidebar } from "@src/common/components";
import { ordersPageWidgetsList } from "@src/shipments/utils/ordersPageWidgetsList";
import { useLocalStorage } from "usehooks-ts";
import { WidgetName } from "@src/common/components/Widgets/dashboardWidgetTypes";
import WidgetContainer from "@src/shipments/components/WidgetContainer/WidgetContainer";

type Props = {};

function OrdersPage({}: Props) {
  // contexts
  const { setDispatchCandidatesWidgetSelectedItem } =
    useDispatchPreviewContext();
  const { selectedWidgetItem } = useCourieStore();
  const { selectedRow } = useShipmentSelectionContext();
  const { dispatchPreviewApiData } = useDispatchPreviewContext();
  // states
  const parentRef = React.createRef<HTMLDivElement>();
  const [cursorWait, setCursorWait] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [lastSelectedWidgetOrdersPage, setLastSelectedWidgetOrdersPage] =
    useLocalStorage<WidgetName | undefined>(
      "lastSelectedWidgetOrdersPage",
      WidgetName.DISPATCH_CANDIDATES
    );

  useEffect(() => {
    if (isMounted === false) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    if (selectedWidgetItem) {
      setDispatchCandidatesWidgetSelectedItem(selectedWidgetItem);
      const selectedDriverId = selectedWidgetItem?.id;
      const selectedDriver: any = dispatchPreviewApiData?.dispatchPreviews.find(
        (dispatchPreview: any) => {
          if (
            dispatchPreview.driver &&
            dispatchPreview.driver.id === selectedDriverId
          ) {
            return dispatchPreview;
          }
        }
      );
    }
  }, [selectedWidgetItem]);

  const cursorClass = cursorWait ? "cursor-wait" : "";

  return (
    <>
      <div
        className={`flex flex-col md:flex-row ${cursorClass}`}
        style={{ height: "calc(100vh - 56px)" }}
      >
        <div className="flex-1 overflow-y-auto relative" ref={parentRef}>
          <ShipmentsTable />
        </div>
        {selectedRow && (
          <>
            <Sidebar.Group className="px-0.5">
              {ordersPageWidgetsList.map((widgetItem: any, i: number) => {
                return (
                  <Tooltip key={i} content={widgetItem.title} placement="left">
                    <Sidebar.Item
                      {...widgetItem}
                      active={widgetItem.name === lastSelectedWidgetOrdersPage}
                      onClick={() =>
                        setLastSelectedWidgetOrdersPage(widgetItem.name)
                      }
                    />
                  </Tooltip>
                );
              })}
            </Sidebar.Group>
            <div className="w-full md:w-1/4 mt-4 md:mt-0 h-full overflow-auto">
              <WidgetContainer
                selectedWidget={lastSelectedWidgetOrdersPage}
                setCusorWait={setCursorWait}
              />
            </div>
          </>
        )}
      </div>
      <Head>
        <title>Orders</title>
      </Head>
    </>
  );
}

export default OrdersPage;
