import { OrderingAlgorithmType } from "@api/graphql/generated/generated-types";
import { RouteViewMode } from "../ChangeDriverRouteView";

export const DefaultTabOrder = [
  OrderingAlgorithmType.Manual,
  OrderingAlgorithmType.LeastDeviation,
  OrderingAlgorithmType.OnTimeFirstReoptimized,
  OrderingAlgorithmType.Reoptimized,
  OrderingAlgorithmType.Beginning,
];

export const DispatchTabOrder = [
  OrderingAlgorithmType.LeastDeviation,
  OrderingAlgorithmType.OnTimeFirstReoptimized,
  OrderingAlgorithmType.Reoptimized,
  OrderingAlgorithmType.Beginning,
  OrderingAlgorithmType.End,
];

export const ChangeDriverTabOrder = [
  OrderingAlgorithmType.LeastDeviation,
  OrderingAlgorithmType.OnTimeFirstReoptimized,
  OrderingAlgorithmType.Beginning,
  OrderingAlgorithmType.End,
];

export const orderingAlgorithmDisplayNames = {
  LEAST_DEVIATION: "Least Deviation",
  ON_TIME_FIRST_REOPTIMIZED: "On Time",
  REOPTIMIZED: "Reroute",
  BEGINNING: "Go First",
  END: "Go Last",
  MANUAL: "Current",
};

export const getTabOrder = (routeViewMode: RouteViewMode) => {
  switch (routeViewMode) {
    case RouteViewMode.ChangeDriver:
      return ChangeDriverTabOrder;
    case RouteViewMode.Dispatch:
      return DispatchTabOrder;
    default:
      return DefaultTabOrder;
  }
};
