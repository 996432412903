import StopDateListTimeline from "@src/common/components/ChangeDriverRouteView/components/StopDateListTimeline";

const HeaderContent = ({
  title,
  selectedStopDate,
  stopDates,
}: {
  title: string;
  selectedStopDate: string | null;
  stopDates: string[];
}) => (
  <div className="flex items-center justify-between">
    <span>{title}</span>
    <div className="flex gap-2">
      {selectedStopDate && (
        <StopDateListTimeline
          listStopDates={stopDates}
          selectedStopDate={selectedStopDate}
        />
      )}
    </div>
  </div>
);

export default HeaderContent;
