import { ArtifactType } from "@api/graphql/generated/generated-types";
import {
  ArrowDownTrayIcon,
  CameraIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { Button, ImageWithCaption } from "@src/common/components";
import { formatDateTime } from "@src/common/lib/DateUtils";
import { ArtifactsAtStop } from "@src/shipments/types";
import Link from "next/link";

type Props = {
  artifactsAtStops: ArtifactsAtStop[];
};

export function ArtifactsView({ artifactsAtStops }: Props) {
  return (
    <div className="flex flex-col items-center gap-10 divide-y">
      {artifactsAtStops
        .sort((a, b) => {
          return a.stopType.localeCompare(b.stopType);
        })
        .map((artifactsAtStop) => {
          return (
            <div key={artifactsAtStop.stopId}>
              <div className="flex-1 flex flex-col gap-10 mt-4">
                {artifactsAtStop.artifacts.map((artifact) => {
                  return (
                    <div
                      key={artifact.id}
                      className="flex-1 flex flex-col gap-1"
                    >
                      <div className="flex-1 flex gap-1 items-center">
                        <div className="flex-init">
                          {artifact.type === ArtifactType.PhotoFile && (
                            <CameraIcon className="h-12 w-12 text-slate-600" />
                          )}
                          {artifact.type === ArtifactType.SignatureFile && (
                            <PencilIcon className="h-12 w-12 text-slate-600" />
                          )}
                        </div>
                        <div className="flex flex-1 flex-col ">
                          <div className="flex-1 text-md font-semibold text-slate-600">
                            {artifactsAtStop.stopType} @{" "}
                            {artifactsAtStop.stopAddress}
                          </div>
                          <div className="flex-init text-xs text-slate-400">
                            {formatDateTime(artifact.timestamp)}
                          </div>
                        </div>
                      </div>

                      <div className="flex-1">
                        <ImageWithCaption
                          src={artifact.url}
                          alt="Proof of Delivery"
                          width={400}
                          height={400}
                          className="rounded-xl"
                          caption={artifact.caption}
                          overlayItem={
                            <Link download href={artifact.url} target="_blank">
                              <Button
                                pill={true}
                                className="bg-slate-200 hover:bg-slate-300"
                              >
                                <ArrowDownTrayIcon className="flex-1 h-6 w-6 text-slate-600" />
                              </Button>
                            </Link>
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}
