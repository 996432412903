import React, { useState } from "react";
import {
  BaseModal,
  BaseModalBody,
  BaseModalHeader,
} from "@src/common/components";
import { OrderAttachment } from "@api/graphql/generated/generated-types";
import FileUpload from "../FileUpload/FileUpload";
import { useShipmentSelectionContext } from "../../context/ShipmentSelectionContext";
import { useShipmentsCoreDataContext } from "@src/shipments/context/ShipmentsCoreData";

interface OrderAttachmentsModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

const OrderAttachmentsModal: React.FC<OrderAttachmentsModalProps> = ({
  isModalOpen,
  onClose,
}) => {
  const { shipmentSelectedForAction } = useShipmentSelectionContext();
  const [uploadedFiles, setUploadedFiles] = useState<OrderAttachment[]>([]);
  const { fetchShipments } = useShipmentsCoreDataContext();
  const renderAttachments = () => {
    const attachments = [
      ...(shipmentSelectedForAction?.order.orderAttachments || []),
      ...uploadedFiles,
    ];

    if (attachments.length === 0) {
      return <p>No files uploaded yet.</p>;
    }

    return (
      <ul className="list-disc pl-5">
        {attachments.map((attachment: OrderAttachment) => (
          <li key={attachment.id} className="mb-2">
            <a
              href={attachment.publicUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              {attachment.fileName}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <BaseModal isOpen={isModalOpen} onClose={onClose}>
      <BaseModalHeader>
        Order Attachments - {shipmentSelectedForAction?.order.displayId}
      </BaseModalHeader>
      <BaseModalBody>
        <div className="mb-6">
          <h2 className="text-md font-semibold mb-2">Upload New File</h2>
          <FileUpload
            setUploadedFile={setUploadedFiles}
            uploadedFile={uploadedFiles}
            onSuccessUpload={() => {
              fetchShipments();
            }}
          />
        </div>
        <div>
          <h2 className="text-md font-semibold mb-2">Uploaded Files</h2>
          {renderAttachments()}
        </div>
      </BaseModalBody>
    </BaseModal>
  );
};

export default OrderAttachmentsModal;
