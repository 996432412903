import { Shipment, Stop } from "@api/graphql/generated/generated-types";

export const findLocationId = (
  stopUserChanged: Stop,
  shipmentSelectedForAction: Shipment
) => {
  const initialStop = shipmentSelectedForAction.stops.find(
    (stop) => stop.id === stopUserChanged.id
  );
  if (initialStop?.streetAddress === stopUserChanged.streetAddress) {
    return initialStop.locationId;
  } else {
    return undefined;
  }
};
export const findGooglePlaceId = (
  stopUserChanged: Stop,
  shipmentSelectedForAction: Shipment
) => {
  const initialStop = shipmentSelectedForAction.stops.find(
    (stop) => stop.id === stopUserChanged.id
  );
  if (initialStop?.streetAddress === stopUserChanged.streetAddress) {
    return undefined;
  } else {
    return stopUserChanged.googlePlaceId;
  }
};
