import React from "react";
import { useShipmentSelectionContext } from "../context/ShipmentSelectionContext";
import CourieButton from "@src/common/components/Button/Button";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";
import { ShipmentStatus } from "@api/graphql/generated/generated-types";

type Props = {
  showSelectAllButton: boolean;
};

function CheckedShipmentsStatus({ showSelectAllButton }: Props) {
  const {
    checkedShipments,
    setIsAllUnassignedChecked,
    isAllUnassignedChecked,
    setCheckedShipments,
  } = useShipmentSelectionContext();
  const { rows, shipmentsOverviewCounts } = useShipmentsCoreDataContext();

  const handleSelectAllUnassigned = () => {
    setIsAllUnassignedChecked(true);
    const shipmentIds = rows
      .map((row) => {
        if (row && row.shipmentStatus === ShipmentStatus.Created) {
          return row.key;
        }
        return;
      })
      .filter(Boolean) as string[];
    setCheckedShipments(shipmentIds);
  };

  if (!shipmentsOverviewCounts) {
    return null;
  }
  return (
    <div className="flex items-center gap-2">
      {isAllUnassignedChecked ? (
        <CourieButton
          color={"gray"}
          size={"xs"}
          onClick={() => {
            setIsAllUnassignedChecked(false);
            setCheckedShipments([]);
          }}
        >
          Clear selection
        </CourieButton>
      ) : (
        showSelectAllButton &&
        rows.length !== shipmentsOverviewCounts.numUnassigned && (
          <CourieButton
            color={"gray"}
            size={"xs"}
            onClick={handleSelectAllUnassigned}
          >
            Select All {shipmentsOverviewCounts?.numUnassigned} unassigned
            orders
          </CourieButton>
        )
      )}
      {isAllUnassignedChecked ? (
        <span className="text-sm font-medium text-primary-500">
          All {shipmentsOverviewCounts?.numUnassigned} unassigned orders are
          selected.
        </span>
      ) : (
        <span className="text-sm font-medium text-primary-500">
          {rows.length === checkedShipments.length
            ? `All ${checkedShipments.length} orders on this page are selected.`
            : `${checkedShipments.length} selected`}
        </span>
      )}
    </div>
  );
}

export default CheckedShipmentsStatus;
