import {
  OrderingAlgorithmType,
  Stop,
} from "@api/graphql/generated/generated-types";

function makeTooltipContent(
  orderingAlgorithmType: OrderingAlgorithmType
): string {
  switch (orderingAlgorithmType) {
    case OrderingAlgorithmType.Manual:
      return "Manually order the tasks.";
    case OrderingAlgorithmType.Beginning:
      return "Go to the new tasks immediately after completing the current active task.";
    case OrderingAlgorithmType.End:
      return "Add the new tasks to the end of the list of tasks to be completed";
    case OrderingAlgorithmType.LeastDeviation:
      return "Optimize the route with minimum possible deviation without changing the existing order of tasks.";
    case OrderingAlgorithmType.Reoptimized:
      return "Re-optimize Total Duration of the route by changing the existing order of tasks.";
    case OrderingAlgorithmType.ReoptimizedMileage:
      return "Re-optimize Total Mileage of the route by changing the existing order of tasks.";
    case OrderingAlgorithmType.OnTimeFirstReoptimized:
      return "Re-optimize On-Timeness first.";
    default:
      return "";
  }
}

export const getFirstTaskSequence = (stop: Stop) => {
  return stop.tasks?.[0]?.sequenceNumber ?? Number.MAX_SAFE_INTEGER;
};
