import React, { useEffect, useState } from "react";
import {
  RouteOptimizationObjectiveType,
  RouteOptimizationVehicleDistributionType,
} from "api/graphql/generated/generated-types";
import SelectableCard from "./SelectableCard";
import { DispatchMode } from "./constants/enums";

type RoutingPenaltySettingsCreateRoutingJobSlideProps = {
  objectiveType: RouteOptimizationObjectiveType;
  setObjectiveType: (objectiveType: RouteOptimizationObjectiveType) => void;
  vehicleDistributionType: RouteOptimizationVehicleDistributionType;
  setVehicleDistributionType: (
    vehicleDistributionType: RouteOptimizationVehicleDistributionType
  ) => void;
  driverSpeeds: { [key: string]: number };
  dispatchMode: DispatchMode;
  setDispatchMode: (dispatchMode: DispatchMode) => void;
};

const singleDriverModeSelection = [
  DispatchMode.Optimize,
  DispatchMode.QuickDispatch,
];

function RoutingPenaltySettingsCreateRoutingJobSlide({
  objectiveType,
  setObjectiveType,
  vehicleDistributionType,
  setVehicleDistributionType,
  driverSpeeds,
  dispatchMode,
  setDispatchMode,
}: RoutingPenaltySettingsCreateRoutingJobSlideProps) {
  useEffect(() => {
    setDispatchMode(DispatchMode.Optimize);
  }, []);

  const isOneDriverSelected = Object.values(driverSpeeds).length === 1;
  const sortedVehicleDistributionTypes = Object.values(
    RouteOptimizationVehicleDistributionType
  ).sort((a, b) => {
    if (a === RouteOptimizationVehicleDistributionType.None) return -1;
    if (b === RouteOptimizationVehicleDistributionType.None) return 1;
    return a.localeCompare(b);
  });

  const makeTitleForObjectiveType = (type: RouteOptimizationObjectiveType) => {
    switch (type) {
      case RouteOptimizationObjectiveType.Distance:
        return "Distance Only";
      case RouteOptimizationObjectiveType.OnTime:
        return "On Time";
      case RouteOptimizationObjectiveType.DistanceAndOnTime:
        return "Balance Distance & On Time";
    }
  };

  const makeTitleForVehicleDistributionType = (
    type: RouteOptimizationVehicleDistributionType
  ) => {
    switch (type) {
      case RouteOptimizationVehicleDistributionType.None:
        return "Choose Best Drivers";
      case RouteOptimizationVehicleDistributionType.BalancedDistribution:
        return "Balanced Workload";
    }
  };

  const makeDescriptionForVehicleDistributionType = (
    type: RouteOptimizationVehicleDistributionType
  ) => {
    switch (type) {
      case RouteOptimizationVehicleDistributionType.None:
        return "Optimize driver selection as long as they return home on time. Prioritizes efficiency, but distribution may be less even sometimes.";
      case RouteOptimizationVehicleDistributionType.BalancedDistribution:
        return "Ensure even more equal workload distribution across drivers, but might result in less optimal routes.";
    }
  };

  return (
    <div className="flex flex-col gap-6 p-4 min-w-[600px]">
      <div className="flex flex-col gap-6">
        {isOneDriverSelected && (
          <div>
            <h2 className="text-lg font-semibold mb-2">Optimization Mode</h2>
            <div className="grid grid-cols-3 gap-4">
              {singleDriverModeSelection.map((type) => (
                <SelectableCard
                  key={type}
                  title={
                    type === DispatchMode.Optimize
                      ? "Optimize Carefully"
                      : "Quick Dispatch"
                  }
                  description={
                    type === DispatchMode.Optimize
                      ? "Carefully optimizes routes for maximum efficiency and allows you to review and make adjustments before dispatching"
                      : "Prioritizes speed over detailed optimization, quickly assigning routes without the option to review or adjust. Not recommended when route optimization is important, especially for large volumes"
                  }
                  value={type}
                  selectedValue={dispatchMode}
                  onChange={setDispatchMode}
                />
              ))}
            </div>
          </div>
        )}
        <div>
          <h2 className="text-lg font-semibold mb-2">Optimization Goal</h2>
          <div className="grid grid-cols-3 gap-4">
            {Object.values(RouteOptimizationObjectiveType).map((type) => (
              <SelectableCard
                disabled={dispatchMode === DispatchMode.QuickDispatch}
                key={type}
                title={makeTitleForObjectiveType(type)}
                description={""}
                value={type}
                selectedValue={objectiveType}
                onChange={setObjectiveType}
              />
            ))}
          </div>
        </div>
        {!isOneDriverSelected && (
          <div>
            <h2 className="text-lg font-semibold mb-2">
              Workload Distribution
            </h2>
            <div className="grid grid-cols-2 gap-4">
              {sortedVehicleDistributionTypes.map((type) => (
                <SelectableCard
                  key={type}
                  title={makeTitleForVehicleDistributionType(type)}
                  description={makeDescriptionForVehicleDistributionType(type)}
                  value={type}
                  selectedValue={vehicleDistributionType}
                  onChange={setVehicleDistributionType}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RoutingPenaltySettingsCreateRoutingJobSlide;
