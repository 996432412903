import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import {
  BaseModal,
  BaseModalBody,
  BaseModalHeader,
  DatePicker,
  Input,
  Label,
  TimePicker,
} from "@src/common/components";
import { useOverridePodMetadataMutation } from "@api/graphql/generated/generated-types";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import CourieButton from "@src/common/components/Button/Button";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";

type FormData = {
  signedBy: string;
  deliveredAt: Date | null;
  deliveryTime: Date | null;
};

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
};

const OverridePODModal = ({ isModalOpen, onClose }: Props) => {
  const { handleSubmit, setValue, control } = useForm<FormData>();
  const { user } = useContext(AuthContext);
  const { selectedShipment, selectedRow } = useShipmentSelectionContext();
  const [overridePodMetadataMutation, { loading }] =
    useOverridePodMetadataMutation();
  const { showToast } = useCourieStore();

  useEffect(() => {
    setValue("signedBy", selectedShipment?.signedByOverride || "");
    if (selectedShipment?.deliveredAtOverride) {
      const deliveredAtDate = moment
        .unix(selectedShipment.deliveredAtOverride)
        .toDate();
      setValue("deliveredAt", deliveredAtDate);
      setValue("deliveryTime", deliveredAtDate);
    } else {
      setValue("deliveredAt", null);
      setValue("deliveryTime", null);
    }
  }, [selectedShipment, setValue, selectedRow]);

  const onSubmit = handleSubmit(({ signedBy, deliveredAt, deliveryTime }) => {
    const combinedDeliveredAt =
      deliveredAt && deliveryTime
        ? moment(deliveredAt)
            .set({
              hour: moment(deliveryTime).hour(),
              minute: moment(deliveryTime).minute(),
            })
            .toDate()
        : null;

    const variables = {
      shipmentId: selectedShipment?.id || "",
      updatedBy: user?.displayName || "",
      signedBy: signedBy || null,
      deliveredAt: moment(combinedDeliveredAt).unix(),
    };
    overridePodMetadataMutation({
      variables,
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        showErrorToast(error, showToast);
      });
  });

  return (
    <BaseModal isOpen={isModalOpen} onClose={onClose}>
      <BaseModalHeader>Override POD</BaseModalHeader>
      <BaseModalBody className="z-0">
        <form onSubmit={onSubmit}>
          <div className="rounded-lg mb-2 flex flex-col">
            <Label>Signed By</Label>
            <Controller
              control={control}
              name="signedBy"
              render={({ field }) => <Input {...field} size="mini" />}
            />
          </div>
          <div className="rounded-lg mb-2 flex flex-col z-50">
            <Label>Delivered At</Label>
            <Controller
              control={control}
              name="deliveredAt"
              render={({ field }) => (
                <DatePicker
                  size="mini"
                  value={field.value}
                  formatString="MMM do, yyyy"
                  onChange={({ date }) => field.onChange(date)}
                />
              )}
            />
          </div>
          <div className="rounded-lg mb-2 flex flex-col z-50">
            <Label>Delivery Time</Label>
            <Controller
              control={control}
              name="deliveryTime"
              render={({ field }) => (
                <TimePicker
                  size="mini"
                  nullable
                  value={field.value ? moment(field.value).format("HH:mm") : ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value === "") {
                      field.onChange(null);
                    } else {
                      const timeMoment = moment(
                        moment().format("YYYY-MM-DD") + "T" + event.target.value
                      );
                      field.onChange(timeMoment.toDate());
                    }
                  }}
                />
              )}
            />
          </div>
          <div className="flex justify-end mt-4">
            <CourieButton type="submit" disabled={loading}>
              Override
            </CourieButton>
          </div>
        </form>
      </BaseModalBody>
    </BaseModal>
  );
};

export default OverridePODModal;
