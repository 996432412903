/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useContext, useEffect } from "react";
import { OrdersPageModalNames } from "../utils/ordersPageModalNames";
import { useShipmentsTableContext } from "./ShipmentsTableContext";
import WarningConfirmationModal from "@src/common/components/Modal/WarningConfirmationModal";
import ProofOfDeliveryModal, {
  ProofOfDeliveryModalSlides,
} from "@src/artifacts/components/ProofOfDeliveryModal";
import ChangeRoutingModal from "@src/shipments/components/EditOrderModalComponent/ChangeRoutingModal";
import { ArtifactsAtStop } from "../types";
import { useCourieStore } from "@src/common/lib/store";
import {
  useForceCompleteShipmentMutation,
  useMarkShipmentAsDeletedMutation,
  useMarkShipmentAsFailedMutation,
  useResetShipmentMutation,
  useSendCommunicationToEndCustomerMutation,
  useUnassignDriverFromShipmentMutation,
} from "@api/graphql/generated/generated-types";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import EditOrderModal from "../components/EditOrderModalComponent/EditOrderModal";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useShipmentsCoreDataContext } from "./ShipmentsCoreData";
import OverridePODModal from "@src/artifacts/components/OverridePODModal";
import ChangeDriverModal from "../components/ChangeDriverModal/ChangeDriverModal";
import CreateRoutingJobModal from "../components/CreateRoutingJobModal/CreateRoutingJobModal";
import { LayersManager } from "baseui/layer";
import { useRouter } from "next/router";
import { useShipmentSelectionContext } from "./ShipmentSelectionContext";
import OrderAttachmentsModal from "../components/OrderAttachmentsModal/OrderAttachmentsModal";
import ForceCompleteModal from "../components/ForceCompleteModal/ForceCompleteModal";
import BulkUnassignDriversModal from "../components/BulkUnassignDriversModal/BulkUnassignDriversModal";

// Modals context
type ModalsContextType = {
  openedModalName: OrdersPageModalNames | null;
  setModalOpen: (open: boolean, modalName: OrdersPageModalNames) => void;
  setSelectedArtifactsAtStops: (artifacts: ArtifactsAtStop[] | null) => void;
  setSelectedShipmentIdToUnassign: (value: string | null) => void;
  setSelectedShipmentIdToFail: (value: string | null) => void;
  setSelectedShipmentIdToReset: (value: string | null) => void;
  setSelectedShipmentIdToDelete: (value: string | null) => void;
  setSelectedShipmentIdToForceComplete: (value: string | null) => void;
  setSelectedShipmentIdPod: (value: string | null) => void;
  key: number;
  setKey: (value: number) => void;
};

export const ModalsContext =
  createContext<ModalsContextType | undefined>(undefined);

export const ModalsProvider = ({ children }: { children: React.ReactNode }) => {
  const [key, setKey] = useState<number>(0);
  const [openedModalName, setOpenedModalName] =
    useState<OrdersPageModalNames | null>(null);
  const [selectedArtifactsAtStops, setSelectedArtifactsAtStops] =
    useState<ArtifactsAtStop[] | null>(null);
  const { fetchShipments, refetchRoutingJobs, routingJobsData } =
    useShipmentsCoreDataContext();
  const { setIsAllUnassignedChecked, setCheckedShipments } =
    useShipmentSelectionContext();
  const { setSelectedShipmentRowToChangeRouting } = useShipmentsTableContext();
  const { showToast } = useCourieStore();

  const { user, courieUser } = useContext(AuthContext);

  // Using the following state to keep track of the opened modal
  // since there are multiple modals on this page. Using only 1 state
  // rather than one step for each modal.
  const [selectedShipmentIdToUnassign, setSelectedShipmentIdToUnassign] =
    useState<string | null>(null);
  const [selectedShipmentIdToReset, setSelectedShipmentIdToReset] =
    useState<string | null>(null);
  const [selectedShipmentIdToFail, setSelectedShipmentIdToFail] =
    useState<string | null>(null);
  const [selectedShipmentIdToDelete, setSelectedShipmentIdToDelete] =
    useState<string | null>(null);

  const [selectedShipmentIdPod, setSelectedShipmentIdPod] =
    useState<string | null>(null);
  const [
    selectedShipmentIdToForceComplete,
    setSelectedShipmentIdToForceComplete,
  ] = useState<string | null>(null);
  const [currentPodModalSlide, setCurrentPodModalSlide] =
    useState<ProofOfDeliveryModalSlides>(ProofOfDeliveryModalSlides.VIEW_POD);
  const router = useRouter();
  const lastJob = routingJobsData?.routingJobs.edges?.[0]?.node;

  const [
    unassignDriverFromShipmentMutation,
    {
      data: unassignDriverFromShipmentMutationData,
      loading: unassignDriverFromShipmentMutationLoading,
      error: unassignDriverFromShipmentMutationError,
    },
  ] = useUnassignDriverFromShipmentMutation();

  const [
    markShipmentAsDeletedMutation,
    {
      data: markShipmentAsDeletedMutationData,
      loading: markShipmentAsDeletedMutationLoading,
      error: markShipmentAsDeletedMutationError,
    },
  ] = useMarkShipmentAsDeletedMutation();

  const [
    markShipmentAsFailedMutation,
    {
      data: markShipmentAsFailedMutationData,
      loading: markShipmentAsFailedMutationLoading,
      error: markShipmentAsFailedMutationError,
    },
  ] = useMarkShipmentAsFailedMutation();

  const [
    resetShipmentMutation,
    {
      data: resetShipmentMutationData,
      loading: resetShipmentMutationLoading,
      error: resetShipmentMutationError,
    },
  ] = useResetShipmentMutation();

  const [
    sendCommunicationToEndCustomerMutation,
    {
      data: sendCommunicationToEndCustomerMutationData,
      loading: sendCommunicationToEndCustomerMutationLoading,
      error: sendCommunicationToEndCustomerMutationError,
    },
  ] = useSendCommunicationToEndCustomerMutation();

  const [
    forceCompleteShipment,
    {
      data: forceCompleteShipmentMutationData,
      loading: forceCompleteShipmentMutationLoading,
      error: forceCompleteShipmentMutationError,
    },
  ] = useForceCompleteShipmentMutation();

  useEffect(() => {
    if (
      unassignDriverFromShipmentMutationData ||
      unassignDriverFromShipmentMutationError
    ) {
      fetchShipments();
      setModalOpen(false, OrdersPageModalNames.UNASSIGN);
    }
    if (unassignDriverFromShipmentMutationError) {
      showErrorToast(unassignDriverFromShipmentMutationError, showToast);
    }
    if (unassignDriverFromShipmentMutationData) {
      showToast({
        message: "The order is unassigned successfully",
        type: "success",
      });
    }
  }, [
    unassignDriverFromShipmentMutationData,
    unassignDriverFromShipmentMutationError,
  ]);

  useEffect(() => {
    if (
      markShipmentAsDeletedMutationData ||
      markShipmentAsDeletedMutationError
    ) {
      fetchShipments();
      setModalOpen(false, OrdersPageModalNames.DELETE);
    }
    if (markShipmentAsDeletedMutationError) {
      showErrorToast(markShipmentAsDeletedMutationError, showToast);
    }
    if (markShipmentAsDeletedMutationData) {
      showToast({
        message: "The order is deleted successfully",
        type: "success",
      });
    }
  }, [markShipmentAsDeletedMutationData, markShipmentAsDeletedMutationError]);

  useEffect(() => {
    if (markShipmentAsFailedMutationData || markShipmentAsFailedMutationError) {
      fetchShipments();
      setModalOpen(false, OrdersPageModalNames.FAIL);
    }
    if (markShipmentAsFailedMutationError) {
      showErrorToast(markShipmentAsFailedMutationError, showToast);
    }
    if (markShipmentAsFailedMutationData) {
      showToast({
        message: "The order is marked as failed successfully",
        type: "success",
      });
    }
  }, [markShipmentAsFailedMutationData, markShipmentAsFailedMutationError]);

  useEffect(() => {
    if (
      forceCompleteShipmentMutationData ||
      forceCompleteShipmentMutationError
    ) {
      fetchShipments();
      setModalOpen(false, OrdersPageModalNames.FORCE_COMPLETE);
    }
    if (forceCompleteShipmentMutationError) {
      showErrorToast(forceCompleteShipmentMutationError, showToast);
    }
    if (forceCompleteShipmentMutationData) {
      showToast({
        message: "The order is force completed successfully",
        type: "success",
      });
    }
  }, [forceCompleteShipmentMutationData, forceCompleteShipmentMutationError]);

  useEffect(() => {
    if (resetShipmentMutationData || resetShipmentMutationError) {
      fetchShipments();
      setModalOpen(false, OrdersPageModalNames.RESET);
    }
    if (resetShipmentMutationError) {
      showErrorToast(resetShipmentMutationError, showToast);
    }
    if (resetShipmentMutationData) {
      showToast({
        message: "The order is reset successfully",
        type: "success",
      });
    }
  }, [resetShipmentMutationData, resetShipmentMutationError]);

  useEffect(() => {
    if (
      sendCommunicationToEndCustomerMutationData ||
      sendCommunicationToEndCustomerMutationError
    ) {
      fetchShipments();
      setModalOpen(false, OrdersPageModalNames.POD);
    }
    if (sendCommunicationToEndCustomerMutationError) {
      showErrorToast(sendCommunicationToEndCustomerMutationError, showToast);
    }
    if (sendCommunicationToEndCustomerMutationData) {
      showToast({
        message: "The POD is sent successfully",
        type: "success",
      });
    }
  }, [
    sendCommunicationToEndCustomerMutationData,
    sendCommunicationToEndCustomerMutationError,
  ]);

  const setModalOpen = (
    isOpen: boolean,
    ordersPageModalNames: OrdersPageModalNames
  ) => {
    setOpenedModalName(isOpen ? ordersPageModalNames : null);
  };

  useEffect(() => {
    return () => {
      setSelectedShipmentIdToUnassign(null);
      setSelectedArtifactsAtStops(null);
      setSelectedShipmentIdPod(null);
      setSelectedShipmentIdToFail(null);
      setSelectedShipmentIdToDelete(null);
      setSelectedShipmentRowToChangeRouting(null);
      setSelectedShipmentIdToForceComplete(null);
    };
  }, []);

  return (
    <ModalsContext.Provider
      value={{
        openedModalName,
        setModalOpen,
        setSelectedArtifactsAtStops,
        setSelectedShipmentIdToUnassign,
        setSelectedShipmentIdToFail,
        setSelectedShipmentIdToReset,
        setSelectedShipmentIdToDelete,
        setSelectedShipmentIdToForceComplete,
        setSelectedShipmentIdPod,
        key,
        setKey,
      }}
    >
      {children}
      <LayersManager zIndex={100}>
        <OverridePODModal
          isModalOpen={openedModalName == OrdersPageModalNames.OVERRIDE_POD}
          onClose={() => {
            setModalOpen(false, OrdersPageModalNames.OVERRIDE_POD);
          }}
        />
        <ProofOfDeliveryModal
          isModalOpen={openedModalName == OrdersPageModalNames.POD}
          onClose={() => {
            setModalOpen(false, OrdersPageModalNames.POD);
            setCurrentPodModalSlide(ProofOfDeliveryModalSlides.VIEW_POD);
          }}
          artifactsAtStops={selectedArtifactsAtStops || []}
          currentSlide={currentPodModalSlide}
          setCurrentSlide={setCurrentPodModalSlide}
          sendCommunicationToEndCustomerMutation={
            sendCommunicationToEndCustomerMutation
          }
          selectedShipmentIdPod={selectedShipmentIdPod || ""}
          isEmailSendLoading={sendCommunicationToEndCustomerMutationLoading}
        />
        <ChangeRoutingModal
          isModalOpen={openedModalName == OrdersPageModalNames.CHANGE_ROUTING}
          onClose={() => {
            setModalOpen(false, OrdersPageModalNames.CHANGE_ROUTING);
          }}
          onSuccess={() => {
            setModalOpen(false, OrdersPageModalNames.CHANGE_ROUTING);
            showToast({
              message: "The routing is changed successfully",
              type: "success",
            });
            fetchShipments();
          }}
        />
        <WarningConfirmationModal
          isModalOpen={openedModalName == OrdersPageModalNames.UNASSIGN}
          setIsModalOpen={(open) =>
            setModalOpen(open, OrdersPageModalNames.UNASSIGN)
          }
          onConfirm={() => {
            if (selectedShipmentIdToUnassign) {
              unassignDriverFromShipmentMutation({
                variables: { shipmentId: selectedShipmentIdToUnassign },
              });
            }
          }}
          onCancel={() => {
            setSelectedShipmentIdToUnassign(null);
            setModalOpen(false, OrdersPageModalNames.UNASSIGN);
          }}
          loading={unassignDriverFromShipmentMutationLoading}
          title={"Unassign Driver"}
          textBody={"Are you sure you want to unassign this driver?"}
          primaryButtonColor={"failure"}
          primaryButtonText={"Unassign"}
        />
        {openedModalName == OrdersPageModalNames.FORCE_COMPLETE && (
          <ForceCompleteModal
            isModalOpen={openedModalName == OrdersPageModalNames.FORCE_COMPLETE}
            onClose={() => {
              setModalOpen(false, OrdersPageModalNames.FORCE_COMPLETE);
            }}
            onSuccess={() => {
              fetchShipments();
            }}
          />
        )}

        <WarningConfirmationModal
          isModalOpen={openedModalName == OrdersPageModalNames.RESET}
          setIsModalOpen={(open) =>
            setModalOpen(open, OrdersPageModalNames.RESET)
          }
          onConfirm={() => {
            if (selectedShipmentIdToReset) {
              resetShipmentMutation({
                variables: {
                  shipmentId: selectedShipmentIdToReset,
                  resetBy: courieUser?.id || user?.uid || "",
                },
              });
            }
          }}
          onCancel={() => {
            setSelectedShipmentIdToReset(null);
            setModalOpen(false, OrdersPageModalNames.RESET);
          }}
          loading={resetShipmentMutationLoading}
          title={"Reset order"}
          textBody={
            "Are you sure you want to reset this order? Please note that this will delete all progress, including assignments, pickups, and Proof of Delivery (POD)."
          }
          primaryButtonColor={"failure"}
          primaryButtonText={"Reset"}
        />
        <WarningConfirmationModal
          isModalOpen={openedModalName == OrdersPageModalNames.DELETE}
          setIsModalOpen={(open) =>
            setModalOpen(open, OrdersPageModalNames.DELETE)
          }
          onConfirm={() => {
            if (selectedShipmentIdToDelete) {
              markShipmentAsDeletedMutation({
                variables: { shipmentId: selectedShipmentIdToDelete },
              });
            }
          }}
          onCancel={() => {
            setSelectedShipmentIdToDelete(null);
            setModalOpen(false, OrdersPageModalNames.DELETE);
          }}
          loading={markShipmentAsDeletedMutationLoading}
          title={"Delete order"}
          textBody={"Are you sure you want to permanently delete this order?"}
          primaryButtonColor={"failure"}
          primaryButtonText={"Delete"}
        />
        <WarningConfirmationModal
          isModalOpen={openedModalName == OrdersPageModalNames.FAIL}
          setIsModalOpen={(open) =>
            setModalOpen(open, OrdersPageModalNames.FAIL)
          }
          onConfirm={() => {
            if (selectedShipmentIdToFail) {
              markShipmentAsFailedMutation({
                variables: { shipmentId: selectedShipmentIdToFail },
              });
            }
          }}
          onCancel={() => {
            setSelectedShipmentIdToFail(null);
            setModalOpen(false, OrdersPageModalNames.FAIL);
          }}
          loading={markShipmentAsFailedMutationLoading}
          title={"Mark order as failed"}
          textBody={"Are you sure you want to mark this order as failed?"}
          primaryButtonColor={"failure"}
          primaryButtonText={"Mark as failed"}
        />
        {openedModalName == OrdersPageModalNames.EDIT && (
          <EditOrderModal
            setIsModalOpen={(open: boolean) =>
              setModalOpen(open, OrdersPageModalNames.EDIT)
            }
            isModalOpen={openedModalName == OrdersPageModalNames.EDIT}
            onConfirm={function (): void {
              setModalOpen(false, OrdersPageModalNames.EDIT);
              setKey((prev) => prev + 1);
            }}
          />
        )}
        <ChangeDriverModal
          setIsModalOpen={(open: boolean) =>
            setModalOpen(open, OrdersPageModalNames.CHANGE_DRIVER)
          }
          isModalOpen={openedModalName == OrdersPageModalNames.CHANGE_DRIVER}
          onConfirm={() => {
            setKey((prev) => prev + 1);
            fetchShipments();
          }}
        />
        {openedModalName == OrdersPageModalNames.CREATE_ROUTING_JOB && (
          <CreateRoutingJobModal
            setIsModalOpen={(open: boolean) =>
              setModalOpen(open, OrdersPageModalNames.CREATE_ROUTING_JOB)
            }
            isModalOpen={
              openedModalName == OrdersPageModalNames.CREATE_ROUTING_JOB
            }
            onClose={() => {
              setModalOpen(false, OrdersPageModalNames.CREATE_ROUTING_JOB);
            }}
            onSuccess={() => {
              setIsAllUnassignedChecked(false);
              setCheckedShipments([]);
              refetchRoutingJobs();
              fetchShipments();
            }}
          />
        )}
        {openedModalName == OrdersPageModalNames.CREATE_ROUTING_JOB_WARNING && (
          <WarningConfirmationModal
            cancelButtonText="View Current Optimization"
            isModalOpen={
              openedModalName == OrdersPageModalNames.CREATE_ROUTING_JOB_WARNING
            }
            setIsModalOpen={(open: boolean) =>
              setModalOpen(
                open,
                OrdersPageModalNames.CREATE_ROUTING_JOB_WARNING
              )
            }
            loading={false}
            onConfirm={() => {
              setModalOpen(
                false,
                OrdersPageModalNames.CREATE_ROUTING_JOB_WARNING
              );
              setModalOpen(true, OrdersPageModalNames.CREATE_ROUTING_JOB);
            }}
            onCancel={() => {
              lastJob && router.push(`/routingjob/${lastJob.id}`);
            }}
            title={"Confirm Optimization Reset"}
            textBody={
              "Are you sure you want to discard the current optimization and start a new one?"
            }
            primaryButtonText={"Continue"}
          />
        )}
        {openedModalName == OrdersPageModalNames.ORDER_ATTACHMENTS && (
          <OrderAttachmentsModal
            isModalOpen={
              openedModalName == OrdersPageModalNames.ORDER_ATTACHMENTS
            }
            onClose={() => {
              setModalOpen(false, OrdersPageModalNames.ORDER_ATTACHMENTS);
            }}
          />
        )}
        {openedModalName == OrdersPageModalNames.BULK_UNASSIGN_DRIVERS && (
          <BulkUnassignDriversModal
            isModalOpen={
              openedModalName == OrdersPageModalNames.BULK_UNASSIGN_DRIVERS
            }
            onClose={() => {
              setModalOpen(false, OrdersPageModalNames.BULK_UNASSIGN_DRIVERS);
            }}
            onSuccess={() => {
              setIsAllUnassignedChecked(false);
              setCheckedShipments([]);
              fetchShipments();
              setModalOpen(false, OrdersPageModalNames.BULK_UNASSIGN_DRIVERS);
            }}
            setIsModalOpen={(open: boolean) =>
              setModalOpen(open, OrdersPageModalNames.BULK_UNASSIGN_DRIVERS)
            }
          />
        )}
      </LayersManager>
    </ModalsContext.Provider>
  );
};

export const useModalsContext = () => {
  const context = useContext(ModalsContext);
  if (context === undefined) {
    throw new Error("useModalsContext must be used within a ModalsProvider");
  }
  return context;
};
