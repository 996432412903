import React from "react";
import { Stop } from "@api/graphql/generated/generated-types";
import classNames from "classnames";

interface MultipStopCellProps {
  pickUpStops?: Stop[] | undefined;
  dropOffStops?: Stop[] | undefined;
}

const countCompletedStops = (stops: Stop[] | undefined) => {
  return stops?.filter((stop) => stop.completedAt).length || 0;
};

export const MultipStopCell: React.FC<MultipStopCellProps> = ({
  pickUpStops,
  dropOffStops,
}) => {
  const totalStops = (pickUpStops?.length || 0) + (dropOffStops?.length || 0);
  const completedStops =
    countCompletedStops(pickUpStops) + countCompletedStops(dropOffStops);

  return (
    <div className="flex flex-col justify-center text-xs">
      <span>{totalStops} stops</span>
      <span
        className={classNames({
          "text-green-500": completedStops === totalStops,
          "font-normal": true,
        })}
      >
        {completedStops} completed
      </span>
    </div>
  );
};
