import { useGenerateOrdersPdfMutation } from "@api/graphql/generated/generated-types";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useState, useEffect, useCallback } from "react";
import { useCourieStore } from "../lib/store";

type LoadingState = Record<string, boolean>;

const useDownloadOrdersPdf = () => {
  const { showToast } = useCourieStore();
  const [loadingStates, setLoadingStates] = useState<LoadingState>({});
  const [generateOrdersPdfMutation, { error }] = useGenerateOrdersPdfMutation();

  useEffect(() => {
    if (error) {
      showErrorToast(error, showToast);
    }
  }, [error, showToast]);

  const handleDownloadPDF = useCallback(
    (orderId: string) => {
      setLoadingStates((prevStates) => ({ ...prevStates, [orderId]: true }));

      generateOrdersPdfMutation({
        variables: {
          orderIds: [orderId],
        },
      })
        .then((res) => {
          if (res.data && res.data.generateOrdersPdf.url) {
            window.open(res.data.generateOrdersPdf.url, "_blank");
          } else {
            throw new Error("No PDF URL returned from the server.");
          }
        })
        .catch((error) => {
          showErrorToast(error.message, showToast);
        })
        .finally(() => {
          setLoadingStates((prevStates) => ({
            ...prevStates,
            [orderId]: false,
          }));
        });
    },
    [generateOrdersPdfMutation, showToast]
  );

  return { loadingStates, handleDownloadPDF, error };
};

export default useDownloadOrdersPdf;
