import React, { useEffect, useMemo, useState } from "react";
import { SparklesIcon, UserMinusIcon } from "@heroicons/react/24/outline";
import CourieButton from "@src/common/components/Button/Button";
import {
  RoutingJobStatus,
  ShipmentStatus,
} from "@api/graphql/generated/generated-types";
import { useModalsContext } from "../context/ShipmentsTableModalsContext";
import { OrdersPageModalNames } from "../utils/ordersPageModalNames";
import CheckedShipmentsStatus from "./CheckedShipmentsStatus";
import { Tooltip } from "@src/common/components";
import { useShipmentFiltersContext } from "../context/ShipmentFiltersContext";
import { ShipmentsPrimaryFilterType } from "../types";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";
import { useShipmentSelectionContext } from "../context/ShipmentSelectionContext";

interface CheckedShipmentsActionsProps {
  showSelectAllButton: boolean;
  lastJob?: {
    status?: RoutingJobStatus;
  } | null;
}

const CheckedShipmentsActions: React.FC<CheckedShipmentsActionsProps> = ({
  showSelectAllButton,
  lastJob,
}) => {
  const { rows } = useShipmentsCoreDataContext();
  const { setModalOpen } = useModalsContext();
  const { selectedPrimaryFilter } = useShipmentFiltersContext();
  const { checkedShipments } = useShipmentSelectionContext();

  const [isOptimizeRoutesDisabled, setIsOptimizeRoutesDisabled] =
    useState(false);
  const [isUnassignDriversDisabled, setIsUnassignDriversDisabled] =
    useState(false);
  const [shouldShowUnassignDriversButton, setShouldShowUnassignDriversButton] =
    useState(false);
  const [shouldShowOptimizeRoutesButton, setShouldShowOptimizeRoutesButton] =
    useState(false);

  const memoizedCheckedShipments = useMemo(
    () => checkedShipments,
    [checkedShipments]
  );

  useEffect(() => {
    const isAllCheckedShipmentsEligibleForOptimize =
      memoizedCheckedShipments.every(
        (shipmentId) =>
          rows.find((row) => row?.key === shipmentId)?.shipmentStatus ===
          ShipmentStatus.Created
      );
    setIsOptimizeRoutesDisabled(!isAllCheckedShipmentsEligibleForOptimize);

    const isAllCheckedShipmentsEligibleForUnassign =
      memoizedCheckedShipments.every(
        (shipmentId) =>
          rows.find((row) => row?.key === shipmentId)?.shipmentStatus ===
            ShipmentStatus.Assigned ||
          rows.find((row) => row?.key === shipmentId)?.shipmentStatus ===
            ShipmentStatus.Routed
      );
    setIsUnassignDriversDisabled(!isAllCheckedShipmentsEligibleForUnassign);
  }, [memoizedCheckedShipments]);

  useEffect(() => {
    setShouldShowUnassignDriversButton(
      selectedPrimaryFilter !== ShipmentsPrimaryFilterType.UNASSIGNED
    );
    setShouldShowOptimizeRoutesButton(
      selectedPrimaryFilter !== ShipmentsPrimaryFilterType.ASSIGNED
    );
  }, [selectedPrimaryFilter]);

  const handleOptimizeRoutesClick = () => {
    if (
      lastJob?.status === RoutingJobStatus.Pending ||
      lastJob?.status === RoutingJobStatus.Processing ||
      lastJob?.status === RoutingJobStatus.Ready
    ) {
      setModalOpen(true, OrdersPageModalNames.CREATE_ROUTING_JOB_WARNING);
      return;
    }
    setModalOpen(true, OrdersPageModalNames.CREATE_ROUTING_JOB);
  };

  const handleUnassignDriversClick = () => {
    setModalOpen(true, OrdersPageModalNames.BULK_UNASSIGN_DRIVERS);
  };

  return (
    <>
      {shouldShowOptimizeRoutesButton && (
        <Tooltip
          content="Optimize Routes"
          placement={"bottom"}
          className="z-50"
        >
          <CourieButton
            size="xs"
            color="purple"
            onClick={handleOptimizeRoutesClick}
            disabled={isOptimizeRoutesDisabled}
          >
            <SparklesIcon className="h-4 w-4 mr-1" />
            Optimize Routes
          </CourieButton>
        </Tooltip>
      )}
      {shouldShowUnassignDriversButton && (
        <Tooltip
          content="Unassign Drivers"
          placement={"bottom"}
          className="z-50"
        >
          <CourieButton
            size="xs"
            color="gray"
            onClick={handleUnassignDriversClick}
            disabled={isUnassignDriversDisabled}
          >
            <UserMinusIcon className="h-4 w-4 mr-1" />
          </CourieButton>
        </Tooltip>
      )}
      {selectedPrimaryFilter === ShipmentsPrimaryFilterType.UNASSIGNED && (
        <CheckedShipmentsStatus showSelectAllButton={showSelectAllButton} />
      )}
    </>
  );
};

export default CheckedShipmentsActions;
