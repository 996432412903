import * as Yup from "yup";

export const orderEditSchema = Yup.object().shape({
  costCenterId: Yup.string().nullable(),
  courierId: Yup.string().required("Courier ID is required"),
  endCustomerId: Yup.string().required("End Customer ID is required"),
  endCustomerReferenceNumber: Yup.string().nullable(),
  id: Yup.string().required("Order ID is required"),
  serviceTypeId: Yup.string().nullable(),
  shipmentUpdates: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required("Shipment ID is required"),
      numPackages: Yup.number().integer().positive().nullable(),
      packageSize: Yup.string().nullable(),
      packageWeightInPounds: Yup.number().positive().nullable(),
      shipmentDate: Yup.date().required("Shipment date is required"),
      type: Yup.string().required("Shipment type is required"),
      stops: Yup.array().of(
        Yup.object().shape({
          companyName: Yup.string().nullable(),
          contactName: Yup.string().nullable(),
          googlePlaceId: Yup.string().required("Google Place ID is required"),
          id: Yup.string().required("Stop ID is required"),
          instruction: Yup.string().nullable(),
          phone: Yup.string().nullable(),
          suite: Yup.string().nullable(),
          stopDate: Yup.date().nullable(),
          timeWindow: Yup.object().shape({
            start: Yup.date(),
            end: Yup.date(),
          }),
          type: Yup.string().required("Stop type is required"),
        })
      ),
    })
  ),
});
