import { BadgeProps } from "flowbite-react";
import React, { ReactNode } from "react";

interface Props extends BadgeProps {
  children: ReactNode;
  tooltipContent: string;
  showBadge: boolean;
}

export function StopTimeStatusBadge({
  children,
  tooltipContent,
  showBadge,
  color,
  ...props
}: Props) {
  return (
    <div>
      {showBadge ? (
        <span {...props} className={` truncate text-${color}-500`}>
          {children}
        </span>
      ) : (
        children
      )}
    </div>
  );
}
