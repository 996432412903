import {
  ShipmentsSortField,
  SortingDirection,
} from "@api/graphql/generated/generated-types";

export const sortFields = [
  {
    value: ShipmentsSortField.OrderDisplayId,
    label: "ID",
  },
  {
    value: ShipmentsSortField.EndCustomerName,
    label: "Customer",
  },
  {
    value: ShipmentsSortField.OriginCity,
    label: "Origin City",
  },
  {
    value: ShipmentsSortField.OriginDispatchZone,
    label: "Origin",
  },
  {
    value: ShipmentsSortField.OriginZip,
    label: "Origin Zip",
  },
  {
    value: ShipmentsSortField.OriginStreetAddress,
    label: "Origin Street Address",
  },
  {
    value: ShipmentsSortField.DestinationCity,
    label: "Destination City",
  },
  {
    value: ShipmentsSortField.DestinationDispatchZone,
    label: "Destination",
  },
  {
    value: ShipmentsSortField.DestinationZip,
    label: "Destination Zip",
  },
  {
    value: ShipmentsSortField.DestinationStreetAddress,
    label: "Destination Street Address",
  },
  {
    value: ShipmentsSortField.PickUpDueTime,
    label: "Pick Up Due Time",
  },
  {
    value: ShipmentsSortField.PickUpReadyTime,
    label: "Pick Up Ready Time",
  },
  {
    value: ShipmentsSortField.PickUpCompanyName,
    label: "Pick Up Company Name",
  },
  {
    value: ShipmentsSortField.DropOffDueTime,
    label: "Drop Off Due Time",
  },
  {
    value: ShipmentsSortField.DropOffReadyTime,
    label: "Drop Off Ready Time",
  },
  {
    value: ShipmentsSortField.DropOffCompanyName,
    label: "Drop Off Company Name",
  },
  {
    value: ShipmentsSortField.Status,
    label: "Status",
  },
  {
    value: ShipmentsSortField.DriverName,
    label: "Driver Name",
  },
];

export const sortingDirections = [
  { label: `${SortingDirection.Asc}`, value: SortingDirection.Asc },
  {
    label: `${SortingDirection.Desc}`,
    value: SortingDirection.Desc,
  },
];
