import React from "react";
import { Tooltip } from "@src/common/components";
import { StopColumn } from "@src/shipments/types";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { Stop, TaskStatus } from "@api/graphql/generated/generated-types";
import { MultipStopCell } from "./MultipStopCell";

export default function StopStatusCell({
  stopBadge,
  dispatchZone,
  eta,
  isLikelyLate,
  taskStatusList,
  arrivedAt,
  completedAt,
  completedPrefix,
  pickUpStops,
  dropOffStops,
  dispatchZoneLine2,
}: {
  stopBadge: StopColumn;
  dispatchZone: string | undefined;
  eta: string | undefined;
  isLikelyLate: Boolean | undefined;
  taskStatusList: TaskStatus[];
  arrivedAt: string | undefined;
  completedAt: string | undefined;
  completedPrefix: string;
  pickUpStops?: Stop[] | undefined;
  dropOffStops?: Stop[] | undefined;
  dispatchZoneLine2: string | undefined;
}) {
  const hasStartedTask = taskStatusList.some(
    (taskStatus) => taskStatus === TaskStatus.Started
  );
  const hasCompletedTask = taskStatusList.some(
    (taskStatus) => taskStatus === TaskStatus.Completed
  );

  const hasMultipleStops = (stops?: Stop[]) =>
    stops?.length && stops?.length > 1;

  const ShowMultiStopCellOrStatus = () => {
    if (hasMultipleStops(pickUpStops) || hasMultipleStops(dropOffStops)) {
      return (
        <MultipStopCell pickUpStops={pickUpStops} dropOffStops={dropOffStops} />
      );
    } else {
      return (
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex flex-init text-xs">{dispatchZone}</div>
            <div>
              {hasStartedTask && !hasCompletedTask && !arrivedAt && (
                <Tooltip content="Driver is currently on the way to this stop.">
                  <ArrowRightCircleIcon className="h-4 w-4 text-green-400" />
                </Tooltip>
              )}
            </div>
          </div>
          {dispatchZoneLine2 && (
            <div className="flex-1 text-xs truncate font-normal">
              {dispatchZoneLine2}
            </div>
          )}
          {eta && !arrivedAt && !completedAt && (
            <div className="flex-1 font-normal">
              <div className="flex flex-row items-center gap-2">
                {isLikelyLate ? (
                  <div className="flex flex-init">
                    <Tooltip content="Likely late based on our estimate. This may change.">
                      <div className="flex flex-init text-xs text-orange-400">
                        ETA {eta}
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <div className="flex flex-init text-xs">ETA {eta}</div>
                )}
              </div>
            </div>
          )}
          {arrivedAt && !completedAt && (
            <div className="flex-1 text-xs flex items-center gap-1 font-normal">
              <div>Arrived {arrivedAt}</div>
            </div>
          )}
          {completedAt && (
            <div className="flex-1 text-xs flex items-center gap-1 text-green-500 truncate font-normal">
              <div>
                {completedPrefix} {completedAt}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-1 items-center">
        <div className="flex-1 font-bold text-sm truncate">
          <div className="flex gap-1 items-center">
            <ShowMultiStopCellOrStatus />
          </div>
        </div>
      </div>
    </div>
  );
}
