import React, { createContext, useContext, ReactNode, useEffect } from "react";
// Import your GraphQL hooks
import {
  Exact,
  GetDriverCurrentLocationQuery,
  OptimizeRouteForDispatchMutation,
  PreviewEtaForRouteQuery,
  useGetDriverCurrentLocationLazyQuery,
  useOptimizeRouteForDispatchMutation,
  usePreviewEtaForRouteLazyQuery,
} from "@api/graphql/generated/generated-types";
import { LazyQueryExecFunction } from "@apollo/client";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";

type DriverRouteDataProviderContextType = {
  getDriverCurrentLocation: LazyQueryExecFunction<
    GetDriverCurrentLocationQuery,
    Exact<{
      driverId: string;
    }>
  >;
  previewEtaForRouteMutation: LazyQueryExecFunction<
    PreviewEtaForRouteQuery,
    Exact<{
      driverId: string;
      stopIds: string | string[];
      baseTime?: any;
    }>
  >;
  optimizeRouteForDispatchMutation: any;
  PreviewEtaForRouteMutationData: PreviewEtaForRouteQuery | undefined;
  DriverWithLocationData: GetDriverCurrentLocationQuery | undefined;
  OptimizeRouteForDispatchMutationData:
    | OptimizeRouteForDispatchMutation
    | null
    | undefined;
  OptimizeRouteForDispatchMutationLoading: boolean;
};

const DriverRouteDataProviderContext =
  createContext<DriverRouteDataProviderContextType | undefined>(undefined);

export const DriverRouteDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { showToast } = useCourieStore();

  const [
    previewEtaForRouteMutation,
    {
      data: PreviewEtaForRouteMutationData,
      loading: PreviewEtaForRouteMutationLoading,
      error: PreviewEtaForRouteMutationError,
    },
  ] = usePreviewEtaForRouteLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [
    getDriverCurrentLocation,
    { data: DriverWithLocationData, error: getDriverCurrentLocationError },
  ] = useGetDriverCurrentLocationLazyQuery({
    pollInterval: 10000,
  });

  const [
    optimizeRouteForDispatchMutation,
    {
      data: OptimizeRouteForDispatchMutationData,
      loading: OptimizeRouteForDispatchMutationLoading,
      error: OptimizeRouteForDispatchMutationError,
    },
  ] = useOptimizeRouteForDispatchMutation();

  useEffect(() => {
    if (PreviewEtaForRouteMutationError) {
      showErrorToast(PreviewEtaForRouteMutationError, showToast);
    }
    if (getDriverCurrentLocationError) {
      showErrorToast(getDriverCurrentLocationError, showToast);
    }
    if (OptimizeRouteForDispatchMutationError) {
      showErrorToast(OptimizeRouteForDispatchMutationError, showToast);
    }
  }, [PreviewEtaForRouteMutationError, getDriverCurrentLocationError]);

  // Provide the functions via context
  const value = {
    getDriverCurrentLocation,
    previewEtaForRouteMutation,
    optimizeRouteForDispatchMutation,
    PreviewEtaForRouteMutationData,
    DriverWithLocationData,
    OptimizeRouteForDispatchMutationData,
    OptimizeRouteForDispatchMutationLoading,
  };

  return (
    <DriverRouteDataProviderContext.Provider value={value}>
      {children}
    </DriverRouteDataProviderContext.Provider>
  );
};

export const useDriverRouteDataProvider = () => {
  const context = useContext(DriverRouteDataProviderContext);
  if (!context) {
    throw new Error(
      "useDriverRouteDataProvider must be used within a DataProvider"
    );
  }
  return context;
};
