import classNames from "classnames";
import moment from "moment";
import React from "react";

type Props = {
  lastReportedTime: number | null;
};

function TextLocationStatus({ lastReportedTime }: Props) {
  const isLive =
    lastReportedTime &&
    moment().diff(moment(lastReportedTime * 1000), "minutes") < 3;

  return (
    <p
      className={classNames({
        "italic truncate": true,
        "text-gray-500": !isLive,
        "text-green-500": isLive,
      })}
    >
      {isLive
        ? "Live"
        : lastReportedTime
        ? moment(lastReportedTime * 1000).fromNow()
        : "No location data"}
    </p>
  );
}

export default TextLocationStatus;
