import { Driver } from "@api/graphql/generated/generated-types";
import {
  CheckCircleIcon,
  MagnifyingGlassPlusIcon,
} from "@heroicons/react/24/outline";
import {
  PopoverTrigger,
  PopoverContent,
  PopoverHeading,
  PopoverDescription,
  Popover,
} from "@src/common/components";
import { Tooltip } from "flowbite-react";
import { useState } from "react";
import DriverTask from "@src/common/components/DriverTask/DriverTask";

export default function PeekDriverTasks({ driver }: { driver: Driver }) {
  const { tasks } = driver;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger>
          <div
            className="w-full cursor-pointer flex justify-center items-center hover:bg-gray-200 rounded-md"
            style={{ height: 40 }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Tooltip content="Peek" placement="bottom">
              <MagnifyingGlassPlusIcon className="w-6 h-6 text-gray-500" />
            </Tooltip>
          </div>
        </PopoverTrigger>
        <PopoverContent
          style={{
            width: 380,
            minWidth: 380,
            maxWidth: 380,
            maxHeight: "40vh",
            overflowY: "auto",
            boxShadow:
              "0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23)",
          }}
          className="absolute z-30 bg-slate-100 border min-w-max p-1 rounded-md"
        >
          <PopoverHeading className="mb-1 text-base font-semibold text-slate-700 ml-1">
            {driver.firstName}
          </PopoverHeading>
          <PopoverDescription>
            <div className="flex flex-col gap-1">
              {tasks.map((task, i) => {
                return (
                  <div key={i} className="bg-white rounded-md border shadow-sm">
                    <DriverTask
                      key={task.id}
                      task={task}
                      index={i}
                      showIndex={true}
                    />
                  </div>
                );
              })}
              {tasks.length === 0 && (
                <div className="text-slate-500 flex text-sm mx-auto my-2">
                  <CheckCircleIcon className="w-5 h-5" />
                  Free
                </div>
              )}
            </div>
          </PopoverDescription>
        </PopoverContent>
      </Popover>
    </>
  );
}
