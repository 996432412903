import React, { ReactNode, MouseEventHandler } from "react";
import { Table } from "@src/common/components/Table/Table";
import classNames from "classnames";
import { SORTED_COLUMN_BG } from "@src/common/lib/tableUtils";
import { useHeaderSorting } from "@src/common/hooks/useHeaderSorting";

interface ShipmentsTableRowCellProps {
  sortedFields?: string[];
  children: ReactNode;
  row: any;
  selectedRow: any | null;
  onClick?: MouseEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
}

export const ShipmentsTableRowCell: React.FC<ShipmentsTableRowCellProps> = ({
  sortedFields,
  children,
  row,
  selectedRow,
  onClick,
  style,
}) => {
  const { areFieldsSorted } = useHeaderSorting();

  return (
    <Table.Cell
      style={style}
      className={classNames({
        "px-4 py-0 text-slate-500": true,
        [SORTED_COLUMN_BG]: sortedFields && areFieldsSorted(sortedFields),
        "bg-blue-100": selectedRow?.key === row?.key,
      })}
      onClick={onClick}
    >
      {children}
    </Table.Cell>
  );
};
