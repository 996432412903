import {
  Location,
  LocationResult,
  useGetOrCreateLocationsMutation,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { useContext, useState } from "react";

export const useLocationIds = () => {
  const { courierId } = useContext(AuthContext);
  const { showToast } = useCourieStore();

  const [
    getOrCreateLocationsMutation,
    { loading: getOrCreateLocationsLoading },
  ] = useGetOrCreateLocationsMutation();

  const [startLocation, setStartLocation] =
    useState<Location | undefined>(undefined);
  const [endLocation, setEndLocation] =
    useState<Location | undefined>(undefined);

  const handleAddressChange = (
    address: google.maps.places.AutocompletePrediction | undefined,
    locationType: "startLocation" | "endLocation"
  ) => {
    if (address) {
      const googlePlaceIds = address.place_id;
      getOrCreateLocationsMutation({
        variables: {
          googlePlaceIds: googlePlaceIds,
          courierId: courierId,
        },
      })
        .then((res) => {
          let suggestedLocations: LocationResult[] | undefined;
          if (res.data) {
            suggestedLocations = res.data?.getOrCreateLocations;
          }
          if (!suggestedLocations) {
            return;
          }
          if (
            !suggestedLocations ||
            suggestedLocations.length === 0 ||
            !suggestedLocations[0]?.location
          ) {
            return;
          }
          if (locationType === "startLocation") {
            setStartLocation(suggestedLocations[0].location);
          } else {
            setEndLocation(suggestedLocations[0].location);
          }
        })
        .catch((err) => {
          showErrorToast(err, showToast);
        });
    } else {
      if (locationType === "startLocation") {
        setStartLocation(undefined);
      } else {
        setEndLocation(undefined);
      }
    }
  };

  return {
    handleAddressChange,
    startLocation,
    setStartLocation,
    endLocation,
    setEndLocation,
  };
};
