import { Stop, StopEta } from "@api/graphql/generated/generated-types";
import DriverTask from "@src/common/components/DriverTask/DriverTask";
import classnames from "classnames";
import { useDriverRouteViewSelectionProvider } from "../contexts/DriverRouteViewSelectionProvider";

export const TaskStopCardComponent = ({
  task,
  index,
  eta,
  stop,
  selectedShipment,
  selectedStopDate,
}: {
  task: any;
  index: number;
  eta?: StopEta;
  stop?: Stop;
  selectedShipment;
  selectedStopDate: string | undefined;
}) => {
  const { hoveredTask, setHoveredTask, setHoverTaskLabel } =
    useDriverRouteViewSelectionProvider();
  const bgColorClass = () => {
    if (hoveredTask?.id === task.id) return "bg-blue-300";
    if ((task.stop?.shipment.id || stop?.shipmentId) === selectedShipment?.id)
      return "bg-blue-100 border-primary-300 ";
    return "bg-white";
  };

  return (
    <div
      className={classnames(
        "mb-2 px-2 rounded-md shadow-sm cursor-move border-2",
        bgColorClass()
      )}
      key={index}
      onMouseEnter={() => {
        setHoveredTask(task);
        setHoverTaskLabel(index + 1);
      }}
      onMouseLeave={() => {
        setHoveredTask(undefined);
        setHoverTaskLabel(undefined);
      }}
    >
      <DriverTask
        task={task.__typename === "Task" ? task : undefined}
        key={task.id || stop?.id}
        index={index}
        showIndex={true}
        stopEta={eta}
        stop={task.__typename === "Task" ? undefined : stop}
        selectedStopDate={selectedStopDate}
      />
    </div>
  );
};
