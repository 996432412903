import {
  CurrencyDollarIcon,
  IdentificationIcon,
  MapIcon,
  QueueListIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const ordersPageWidgetsList = [
  {
    icon: <IdentificationIcon className="h-6 w-6" />,
    title: "Drivers",
    name: "dispatch-candidates",
  },
  {
    icon: <QueueListIcon className="h-6 w-6" />,
    title: "Order Detail",
    name: "order-details",
  },
  {
    icon: <MapIcon className="h-6 w-6" />,
    title: "Map",
    name: "drivers-map",
  },
  {
    icon: <CurrencyDollarIcon className="h-6 w-6" />,
    title: "Price",
    name: "orders-price",
  },
  {
    icon: <UsersIcon className="h-6 w-6" />,
    title: "Customer",
    name: "customer",
  },
];
