import { createContext, useContext, useEffect, useState } from "react";
import { useShipmentSelectionContext } from "./ShipmentSelectionContext";
import { useShipmentsCoreDataContext } from "./ShipmentsCoreData";
import { useCourieStore } from "@src/common/lib/store";
import { WidgetName } from "@src/common/components/Widgets/dashboardWidgetTypes";

interface ShipmentKeyboardControlValues {
  keyboardSelectionState: KeyboardSelectionState;
  setKeyboardSelectionState: (value: KeyboardSelectionState) => void;
  keyBoardSelectedDispatchCandidateIndex: number | undefined;
  setKeyBoardSelectedDispatchCandidateIndex: (
    value: number | undefined
  ) => void;
  onExitDispatchModalKeyboardState: () => void;
  resetKeyboardStates: () => void;
}

const ShipmentKeyboardControlContext =
  createContext<ShipmentKeyboardControlValues | undefined>(undefined);

export enum KeyboardSelectionState {
  NONE = "NONE",
  SHIPMENT_SELECT = "SHIPMENT_SELECT",
  DISPATCH_CANDIDATE_SELECT = "DISPATCH_CANDIDATE_SELECT",
  DISPATCH_MODAL_NEEDS_TO_OPEN = "DISPATCH_MODAL_NEEDS_TO_OPEN",
}

type Props = {
  children: React.ReactNode;
};

const ShipmentKeyboardControlProvider = ({ children }: Props) => {
  const [keyboardSelectionState, setKeyboardSelectionState] =
    useState<KeyboardSelectionState>(KeyboardSelectionState.SHIPMENT_SELECT);
  const [
    keyBoardSelectedDispatchCandidateIndex,
    setKeyBoardSelectedDispatchCandidateIndex,
  ] = useState<number | undefined>(undefined);
  const { indexToRows, shipmentIdToIndex } = useShipmentsCoreDataContext();
  const { selectedRow, handleSelectRow } = useShipmentSelectionContext();

  const resetKeyboardStates = () => {
    setKeyboardSelectionState(KeyboardSelectionState.SHIPMENT_SELECT);
    setKeyBoardSelectedDispatchCandidateIndex(undefined);
  };
  const { selectedWidget } = useCourieStore();

  const handleKeyPressEnter = () => {
    switch (keyboardSelectionState) {
      case KeyboardSelectionState.SHIPMENT_SELECT:
        if (
          selectedRow?.driverId === null ||
          selectedRow?.driverId === undefined
        ) {
          setKeyboardSelectionState(
            KeyboardSelectionState.DISPATCH_CANDIDATE_SELECT
          );
          setKeyBoardSelectedDispatchCandidateIndex(0);
        }
        break;
      case KeyboardSelectionState.DISPATCH_CANDIDATE_SELECT:
        setKeyboardSelectionState(
          KeyboardSelectionState.DISPATCH_MODAL_NEEDS_TO_OPEN
        );
        break;
      case KeyboardSelectionState.DISPATCH_MODAL_NEEDS_TO_OPEN:
        // handled by the modal
        break;
      default:
        break;
    }
  };

  const handleKeyPressEscape = () => {
    switch (keyboardSelectionState) {
      case KeyboardSelectionState.SHIPMENT_SELECT:
        // DO NOTHING
        break;
      case KeyboardSelectionState.DISPATCH_CANDIDATE_SELECT:
        setKeyboardSelectionState(KeyboardSelectionState.SHIPMENT_SELECT);
        setKeyBoardSelectedDispatchCandidateIndex(undefined);
        break;
      case KeyboardSelectionState.DISPATCH_MODAL_NEEDS_TO_OPEN:
        setKeyboardSelectionState(
          KeyboardSelectionState.DISPATCH_CANDIDATE_SELECT
        );
        break;
      default:
        break;
    }
  };

  const handleKeyPressArrowUp = () => {
    switch (keyboardSelectionState) {
      case KeyboardSelectionState.SHIPMENT_SELECT:
        if (selectedRow === undefined || selectedRow === null) {
          break;
        }
        const index: number = shipmentIdToIndex[selectedRow?.id];
        const prevIndex: number = indexToRows[index - 1] ? index - 1 : index;
        handleSelectRow(indexToRows[prevIndex]);
        break;
      case KeyboardSelectionState.DISPATCH_CANDIDATE_SELECT:
        setKeyBoardSelectedDispatchCandidateIndex(
          keyBoardSelectedDispatchCandidateIndex === undefined
            ? 0
            : keyBoardSelectedDispatchCandidateIndex - 1
        );
        break;
      case KeyboardSelectionState.DISPATCH_MODAL_NEEDS_TO_OPEN:
        // DO NOTHING
        break;
      default:
        break;
    }
  };

  const handleKeyPressArrowDown = () => {
    switch (keyboardSelectionState) {
      case KeyboardSelectionState.SHIPMENT_SELECT:
        if (selectedRow === undefined || selectedRow === null) {
          break;
        }
        const index: number = shipmentIdToIndex[selectedRow?.id];
        const nextIndex: number = indexToRows[index + 1] ? index + 1 : 0;
        handleSelectRow(indexToRows[nextIndex]);
        break;
      case KeyboardSelectionState.DISPATCH_CANDIDATE_SELECT:
        setKeyBoardSelectedDispatchCandidateIndex(
          keyBoardSelectedDispatchCandidateIndex === undefined
            ? 0
            : keyBoardSelectedDispatchCandidateIndex + 1
        );
        break;
      case KeyboardSelectionState.DISPATCH_MODAL_NEEDS_TO_OPEN:
        // DO NOTHING
        break;
      default:
        break;
    }
  };

  const onExitDispatchModalKeyboardState = () => {
    if (keyBoardSelectedDispatchCandidateIndex !== undefined) {
      setKeyboardSelectionState(
        KeyboardSelectionState.DISPATCH_CANDIDATE_SELECT
      );
    } else {
      setKeyboardSelectionState(KeyboardSelectionState.SHIPMENT_SELECT);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        ["INPUT", "TEXTAREA", "SELECT"].includes(
          (document.activeElement as HTMLElement).tagName
        )
      ) {
        return;
      }
      if (event.key === "Enter") {
        handleKeyPressEnter();
      }
      if (event.key === "Escape") {
        handleKeyPressEscape();
      }
      if (event.key === "ArrowUp") {
        handleKeyPressArrowUp();
      }
      if (event.key === "ArrowDown") {
        handleKeyPressArrowDown();
      }
    };
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    selectedRow,
    indexToRows,
    handleSelectRow,
    shipmentIdToIndex,
    keyboardSelectionState,
  ]);

  useEffect(() => {
    if (selectedWidget === null || selectedWidget === undefined) {
      return;
    }
    if (selectedWidget === WidgetName.ORDER_DETAILS) {
      setKeyboardSelectionState(KeyboardSelectionState.NONE);
    } else {
      setKeyboardSelectionState(KeyboardSelectionState.SHIPMENT_SELECT);
    }
  }, [selectedWidget]);

  return (
    <ShipmentKeyboardControlContext.Provider
      value={{
        keyboardSelectionState,
        setKeyboardSelectionState,
        keyBoardSelectedDispatchCandidateIndex,
        setKeyBoardSelectedDispatchCandidateIndex,
        onExitDispatchModalKeyboardState,
        resetKeyboardStates,
      }}
    >
      {children}
    </ShipmentKeyboardControlContext.Provider>
  );
};

const useKeyboardControlContext = () => {
  const context = useContext(ShipmentKeyboardControlContext);
  if (context === undefined) {
    throw new Error(
      "useKeyboardControlContext must be used within a ShipmentKeyboardControlProvider"
    );
  }
  return context;
};

export { ShipmentKeyboardControlProvider, useKeyboardControlContext };
