import {
  ShipmentStopType,
  Stop,
  Task,
} from "@api/graphql/generated/generated-types";
import { SortableItem } from "./SortableListFormatters";

interface TaskListOrderingValidationResult {
  isValidOrder: boolean;
  errorMessage: string | null;
}

function validateTaskListOrdering(
  assignedTasksList: SortableItem[]
): TaskListOrderingValidationResult {
  if (assignedTasksList.length < 2) {
    return {
      isValidOrder: true,
      errorMessage: null,
    };
  }

  const stopIdToIndex: Map<string, number> = new Map<string, number>();
  const shipmentIdToStops: Map<string, SortableItem[]> = new Map<
    string,
    SortableItem[]
  >();
  assignedTasksList.forEach((stop, index) => {
    stopIdToIndex.set(stop.id, index);
    const shipmentId = stop.meta.shipmentId;
    if (!shipmentIdToStops.get(shipmentId)) {
      shipmentIdToStops.set(shipmentId, []);
    }
    shipmentIdToStops.get(shipmentId)!.push(stop);
  });

  let isValid = true;
  let errorMessage: any = null;
  shipmentIdToStops.forEach(
    (stopsInGroup: SortableItem[], shipmentId: string) => {
      const pickUpStops: SortableItem[] = stopsInGroup.filter(
        (stop) => stop.meta.stopType === ShipmentStopType.PickUp
      );
      const dropOffStops: SortableItem[] = stopsInGroup.filter(
        (stop) => stop.meta.stopType === ShipmentStopType.DropOff
      );
      const returnStops: SortableItem[] = stopsInGroup.filter(
        (stop) => stop.meta.stopType === ShipmentStopType.Return
      );

      pickUpStops.forEach((pickUpStop: SortableItem) => {
        dropOffStops.forEach((dropOffStop: SortableItem) => {
          if (
            stopIdToIndex.get(pickUpStop.id)! >
            stopIdToIndex.get(dropOffStop.id)!
          ) {
            isValid = false;
            errorMessage = "Pick Up stop cannot be placed after Drop Off stop.";
          }
        });
      });

      dropOffStops.forEach((dropoffStop: SortableItem) => {
        returnStops.forEach((returnStop: SortableItem) => {
          if (
            stopIdToIndex.get(dropoffStop.id)! >
            stopIdToIndex.get(returnStop.id)!
          ) {
            isValid = false;
            errorMessage = "Drop Off stop cannot be placed after Return stop.";
          }
        });
      });
    }
  );

  return {
    isValidOrder: isValid,
    errorMessage: errorMessage,
  };
}

export default validateTaskListOrdering;

export const isTasksInValidOrder = (allTasks: Task[]): boolean => {
  const tasksByShipmentId: Record<string, any[]> = allTasks.reduce(
    (acc, task) => {
      const id = task.stop.shipment.id;
      if (!acc[id]) acc[id] = [];
      acc[id].push(task);
      return acc;
    },
    {}
  );
  for (const tasks of Object.values(tasksByShipmentId)) {
    let hasDropOff = false;
    let hasReturn = false;
    for (const task of tasks) {
      if (task.stop.type === ShipmentStopType.DropOff) hasDropOff = true;
      if (task.stop.type === ShipmentStopType.PickUp && hasDropOff)
        return false; // Pick Up after Drop Off is invalid
      if (task.stop.type === ShipmentStopType.Return) hasReturn = true;
      if (task.stop.type === ShipmentStopType.DropOff && hasReturn)
        return false; // Drop Off after Return is invalid
    }
  }

  return true;
};

export function validateRouteDetailsOrdering(
  routeDetails: (Task | Stop)[]
): TaskListOrderingValidationResult {
  if (routeDetails.length < 2) {
    return {
      isValidOrder: true,
      errorMessage: null,
    };
  }

  const stops: Stop[] = [];

  routeDetails.forEach((detail) => {
    if (detail.__typename === "Stop") {
      stops.push(detail as Stop);
    } else if (detail.__typename === "Task" && detail.stop) {
      stops.push(detail.stop);
    }
  });

  const stopIdToIndex: Map<string, number> = new Map<string, number>();
  const shipmentIdToStops: Map<string, Stop[]> = new Map<string, Stop[]>();
  stops.forEach((stop, index) => {
    stopIdToIndex.set(stop.id, index);
    const shipmentId = stop.shipmentId;
    if (!shipmentIdToStops.get(shipmentId)) {
      shipmentIdToStops.set(shipmentId, []);
    }
    shipmentIdToStops.get(shipmentId)!.push(stop);
  });

  let isValid = true;
  let errorMessage: any = null;
  shipmentIdToStops.forEach((stopsInGroup: Stop[], shipmentId: string) => {
    const pickUpStops: Stop[] = stopsInGroup.filter(
      (stop) => stop.type === ShipmentStopType.PickUp
    );
    const dropOffStops: Stop[] = stopsInGroup.filter(
      (stop) => stop.type === ShipmentStopType.DropOff
    );
    const returnStops: Stop[] = stopsInGroup.filter(
      (stop) => stop.type === ShipmentStopType.Return
    );

    pickUpStops.forEach((pickUpStop: Stop) => {
      dropOffStops.forEach((dropOffStop: Stop) => {
        if (
          stopIdToIndex.get(pickUpStop.id)! > stopIdToIndex.get(dropOffStop.id)!
        ) {
          isValid = false;
          errorMessage = "Pick Up stop cannot be placed after Drop Off stop.";
        }
      });
    });

    dropOffStops.forEach((dropoffStop: Stop) => {
      returnStops.forEach((returnStop: Stop) => {
        if (
          stopIdToIndex.get(dropoffStop.id)! > stopIdToIndex.get(returnStop.id)!
        ) {
          isValid = false;
          errorMessage = "Drop Off stop cannot be placed after Return stop.";
        }
      });
    });
  });

  return {
    isValidOrder: isValid,
    errorMessage: errorMessage,
  };
}
