import React, { ReactNode, useState } from "react";
import { Badge } from "flowbite-react";
import { DriverAvatar } from "@src/common/components/Avatar";
import classNames from "classnames";
import PeekDriverTasks from "./PeekDriverTasks";
import { Driver } from "@api/graphql/generated/generated-types";
import { MAX_WIDTH_TO_HANDLE_IN_BETWEEN_NEGATIVE_SPACE_IN_LARGE_SCREEN } from "../constants/styleConstants";

type Props = {
  children?: React.ReactNode;
  title: string;
  fullName?: string;
  subText: ReactNode;
  badgeText?: string;
  image: string;
  expanded: boolean;
  onExpand: () => void;
  onCollapse: () => void;
  onClick: () => void;
  showBorder?: boolean;
  isKeyboardSelected?: boolean;
  driver: Driver;
};

export function ExpandableDriverCard({
  title,
  fullName = "",
  subText,
  badgeText,
  image,
  expanded,
  onExpand,
  onCollapse,
  onClick,
  children,
  showBorder = false,
  isKeyboardSelected = false,
  driver,
}: Props) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className={classNames({
        "overflow-hidden pl-2 pr-2 mb-1 mx-auto": true,
        "bg-blue-50": isKeyboardSelected,
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        maxWidth: MAX_WIDTH_TO_HANDLE_IN_BETWEEN_NEGATIVE_SPACE_IN_LARGE_SCREEN,
      }}
    >
      <div
        className={`${
          expanded || isKeyboardSelected
            ? "bg-blue-50 hover:bg-blue-100"
            : "bg-white hover:bg-gray-100"
        } flex flex-col py-2 pl-2 pr-2 rounded-md`}
      >
        <div className="flex-1 flex items-center">
          <div className="flex-1 flex items-center">
            <div style={{ width: 40 }}>
              {isHovered ? (
                // eslint-disable-next-line react/jsx-no-undef
                <PeekDriverTasks driver={driver} />
              ) : (
                <DriverAvatar
                  driverPhoto={image}
                  driverName={fullName || title}
                  size={35}
                  isActive={showBorder}
                />
              )}
            </div>
            <div className="flex-1 ml-3 cursor-pointer" onClick={onClick}>
              <div className="flex-1 flex items-center truncate">
                <span className="flex-1 text-slate-500 truncate text-base font-bold">
                  {title}
                </span>
                {badgeText && (
                  <div className="flex flex-1">
                    <Badge
                      size="xs"
                      color="success"
                      className="flex-initial ml-2"
                    >
                      {badgeText}
                    </Badge>
                  </div>
                )}
              </div>
              <span className="text-gray-500 text-xs truncate">{subText}</span>
            </div>
          </div>
          {/* <ExpandChevronIcon
            expanded={expanded}
            onExpand={onExpand}
            onCollapse={onCollapse}
          /> */}
        </div>

        {expanded && <div className="flex-1">{children}</div>}
      </div>
    </div>
  );
}
