import { Modal } from "@src/common/components";
import { ArtifactsAtStop } from "@src/shipments/types";
import { PodViewSlide } from "./PodViewSlide";
import { SendPodEmailSlide } from "./SendPodEmailSlide";
type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  artifactsAtStops: ArtifactsAtStop[];
  currentSlide: ProofOfDeliveryModalSlides;
  setCurrentSlide: (value: ProofOfDeliveryModalSlides) => void;
  sendCommunicationToEndCustomerMutation: any;
  selectedShipmentIdPod: string;
  isEmailSendLoading: boolean;
};

export enum ProofOfDeliveryModalSlides {
  VIEW_POD = "VIEW_POD",
  SEND_EMAIL = "SEND_EMAIL",
}

function ProofOfDeliveryModal({
  isModalOpen,
  onClose,
  artifactsAtStops,
  currentSlide,
  setCurrentSlide,
  sendCommunicationToEndCustomerMutation,
  selectedShipmentIdPod,
  isEmailSendLoading,
}: Props) {
  return (
    <Modal show={isModalOpen} dismissible={true} onClose={onClose} size={"3xl"}>
      <Modal.Header>Proof of Delivery</Modal.Header>
      {currentSlide === ProofOfDeliveryModalSlides.VIEW_POD && (
        <PodViewSlide
          artifactsAtStops={artifactsAtStops}
          onClose={onClose}
          setCurrentSlide={setCurrentSlide}
        />
      )}
      {currentSlide === ProofOfDeliveryModalSlides.SEND_EMAIL && (
        <SendPodEmailSlide
          onClose={onClose}
          setCurrentSlide={setCurrentSlide}
          sendCommunicationToEndCustomerMutation={
            sendCommunicationToEndCustomerMutation
          }
          selectedShipmentIdPod={selectedShipmentIdPod}
          isLoading={isEmailSendLoading}
          isModalOpen={isModalOpen}
        />
      )}
    </Modal>
  );
}

export default ProofOfDeliveryModal;
