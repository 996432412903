/* eslint-disable react-hooks/exhaustive-deps */
import {
  CustomFieldValueInput,
  MutationFullUpdateOrderArgs,
  ShipmentStatus,
  ShipmentType,
  Stop,
  useFullUpdateOrderMutation,
} from "@api/graphql/generated/generated-types";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import moment from "moment";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { adjustTimeWindow } from "../utils/stopTimeStatusDisplayer";
import { getFirstTaskSequence } from "../utils/typeConvertors";
import { useShipmentSelectionContext } from "../context/ShipmentSelectionContext";
import { useCourieStore } from "@src/common/lib/store";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";
import { EditOrderModalSlides } from "../components/EditOrderModalComponent/EditOrderModal";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { findGooglePlaceId, findLocationId } from "../utils/stopFieldFinders";

type EditOrderFormSubmitProps = {
  onConfirm: () => void;
  reset: UseFormReset<FieldValues>;
  setCurrentSlide: Dispatch<SetStateAction<EditOrderModalSlides>>;
  isDifferentStopDate: boolean;
};

const useEditOrderFormSubmit = ({
  onConfirm,
  reset,
  setCurrentSlide,
  isDifferentStopDate,
}: EditOrderFormSubmitProps) => {
  const { courieUser, isEmployee, customClaims } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  const { refetchGetShipments } = useShipmentsCoreDataContext();
  const [fullUpdateOrderMutation, { loading }] = useFullUpdateOrderMutation();
  const { setShipmentSelectedForAction, shipmentSelectedForAction } =
    useShipmentSelectionContext();
  const [stopIdsToDelete, setStopIdsToDelete] = useState<string[]>([]);

  const onSubmit = (data: any) => {
    data = data as MutationFullUpdateOrderArgs;
    if (!shipmentSelectedForAction) {
      return;
    }
    const endCustomerId = data.endCustomerId;
    const courierId = data.courierId;
    const orderId = data.id;
    const shipmentDate = moment(data.shipmentDate).format("YYYY-MM-DD");
    const costCenterId = data.costCenterId;
    const serviceTypeid = data.serviceTypeId;
    const { updatedStops, stopsToCreate } = data.stops.reduce(
      (acc, stop) => {
        if (stop.id) {
          const { streetAddress, deliverByDate, timeWindow, ...rest } = stop;
          const isDeliveryDateAfterShipmentDate =
            stop.deliverByDate &&
            moment(stop.deliverByDate).isAfter(moment(shipmentDate));
          const adjustedTimeWindow =
            stop.deliverByDate && isDeliveryDateAfterShipmentDate
              ? adjustTimeWindow(
                  timeWindow,
                  moment(stop.deliverByDate).format("YYYY-MM-DD")
                )
              : adjustTimeWindow(timeWindow, shipmentDate);
          const locationId = findLocationId(stop, shipmentSelectedForAction);
          const googlePlaceId = findGooglePlaceId(
            stop,
            shipmentSelectedForAction
          );
          acc.updatedStops.push({
            ...rest,
            timeWindow: adjustedTimeWindow,
            locationId: googlePlaceId ? undefined : locationId,
            googlePlaceId: locationId ? undefined : googlePlaceId,
          });
        } else {
          const { deliverByDate, timeWindow, ...rest } = stop;
          const adjustedTimeWindow = stop.deliverByDate
            ? adjustTimeWindow(
                timeWindow,
                moment(stop.deliverByDate).format("YYYY-MM-DD")
              )
            : adjustTimeWindow(timeWindow, shipmentDate);
          acc.stopsToCreate.push({
            ...rest,
            timeWindow: adjustedTimeWindow,
          });
        }
        return acc;
      },
      { updatedStops: [], stopsToCreate: [] }
    );

    data.stops = updatedStops;

    const sortedStops = updatedStops
      .sort((a: Stop, b: Stop) => {
        const taskSequenceDifference =
          getFirstTaskSequence(a) - getFirstTaskSequence(b);
        if (taskSequenceDifference !== 0) return taskSequenceDifference;
        if (a.type === "PICK_UP" && b.type !== "PICK_UP") return -1;
        if (a.type !== "PICK_UP" && b.type === "PICK_UP") return 1;
        return 0;
      })
      .map((stop) => {
        return {
          ...stop,
          stopDate: stop.stopDate
            ? moment(stop.stopDate).format("YYYY-MM-DD")
            : undefined,
        };
      });
    data.stops = sortedStops;
    let stopIdsToDeleteOnSubmit = stopIdsToDelete;
    const copyOfStopsToCreate = [...stopsToCreate];

    copyOfStopsToCreate.map((stop) => {
      stop.stopDate = stop.stopDate
        ? moment(stop.stopDate).format("YYYY-MM-DD")
        : undefined;
      return stop;
    });

    if (endCustomerId && courierId && orderId && shipmentSelectedForAction) {
      let packageSize = undefined;
      if (data.packageSize && typeof data.packageSize[0] === "object") {
        packageSize = data.packageSize[0].id;
      } else if (typeof data.packageSize === "string") {
        packageSize = data.packageSize;
      }

      const customFieldValuesToReplace: CustomFieldValueInput[] | undefined =
        data.customFieldValuesToReplace &&
        Object.keys(data.customFieldValuesToReplace).map((fieldId) => ({
          fieldId,
          valueText:
            data.customFieldValuesToReplace &&
            data.customFieldValuesToReplace[fieldId],
        }));
      fullUpdateOrderMutation({
        variables: {
          id: orderId,
          courierId: courierId,
          caller: data.caller,
          endCustomerId: endCustomerId,
          shipmentUpdates: [
            {
              id: shipmentSelectedForAction.id,
              shipmentDate,
              numPackages: data.numPackages === "" ? null : data.numPackages,
              packageSize,
              packageWeightInPounds:
                data.packageWeightInPounds === ""
                  ? null
                  : data.packageWeightInPounds,
              packageTypeId: data.packageTypeId,
              stops: data.stops,
              type: data.type,
              stopIdsToDelete: stopIdsToDeleteOnSubmit,
              stopsToCreate:
                copyOfStopsToCreate.length > 0
                  ? copyOfStopsToCreate
                  : undefined,
              packageDescription: data.packageDescription,
            },
          ],
          endCustomerReferenceNumber: data.endCustomerReferenceNumber,
          serviceTypeId: serviceTypeid || undefined,
          costCenterId: costCenterId || undefined,
          customFieldValuesToReplace: customFieldValuesToReplace || undefined,
          updatedBy: isEmployee
            ? "EMPLOYEE"
            : courieUser?.id || customClaims?.courieUserId || undefined,
        },
      })
        .then(() => {
          refetchGetShipments();
          showToast({
            type: "success",
            message: "Order updated successfully",
          });

          const isRouteChangeNeeded =
            (isDifferentStopDate ||
              (stopsToCreate && stopsToCreate.length > 0)) &&
            (shipmentSelectedForAction.status === ShipmentStatus.Assigned ||
              shipmentSelectedForAction.status === ShipmentStatus.InTransit ||
              shipmentSelectedForAction.status === ShipmentStatus.Routed);

          if (isRouteChangeNeeded) {
            setCurrentSlide &&
              setCurrentSlide(EditOrderModalSlides.CHANGE_ROUTE);
          } else {
            onConfirm();
            setShipmentSelectedForAction(undefined);
            reset();
          }
        })
        .catch((err) => {
          showErrorToast(err, showToast);
        });
    }
  };

  return {
    onSubmit,
    loading,
    stopIdsToDelete,
    setStopIdsToDelete,
  };
};

export default useEditOrderFormSubmit;
