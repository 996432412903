import {
  FetchDriverTasksByDateAndStatusQuery,
  GetDispatchPreviewsQuery,
  useFetchDriverTasksByDateAndStatusLazyQuery,
  useGetDispatchPreviewsLazyQuery,
} from "@api/graphql/generated/generated-types";
import { ApolloError } from "@apollo/client";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import React, { createContext, useContext, ReactNode, useEffect } from "react";

interface CandidatesGraphQLContextProps {
  FetchDriverTasksByDateAndStatus: ReturnType<
    typeof useFetchDriverTasksByDateAndStatusLazyQuery
  >[0];
  FetchDriverTasksByDateAndStatusData:
    | FetchDriverTasksByDateAndStatusQuery
    | undefined;
  FetchDriverTasksByDateAndStatusLoading: boolean;
  FetchDriverTasksByDateAndStatusError: ApolloError | undefined;

  getDispatchPreviews: ReturnType<typeof useGetDispatchPreviewsLazyQuery>[0];
  dispatchPreviewApiData: GetDispatchPreviewsQuery | undefined;
  dispatchPreviewLoading: boolean;
  dispatchPreviewError: ApolloError | undefined;
  dispatchPreviewStartPolling: ReturnType<
    typeof useGetDispatchPreviewsLazyQuery
  >[1]["startPolling"];
  dispatchPreviewStopPolling: ReturnType<
    typeof useGetDispatchPreviewsLazyQuery
  >[1]["stopPolling"];

  getDispatchPreviewsOffline: ReturnType<
    typeof useGetDispatchPreviewsLazyQuery
  >[0];
  dispatchPreviewApiDataOffline: GetDispatchPreviewsQuery | undefined;
  dispatchPreviewLoadingOffline: boolean;
  dispatchPreviewErrorOffline: ApolloError | undefined;

  getDispatchPreviewsSpecialized: ReturnType<
    typeof useGetDispatchPreviewsLazyQuery
  >[0];
  dispatchPreviewApiDataSpecialized: GetDispatchPreviewsQuery | undefined;
  dispatchPreviewLoadingSpecialized: boolean;
  dispatchPreviewErrorSpecialized: ApolloError | undefined;
}

const CandidatesGraphQLContext =
  createContext<CandidatesGraphQLContextProps | undefined>(undefined);

export const CandidatesGraphQLProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { showToast } = useCourieStore();
  const [
    FetchDriverTasksByDateAndStatus,
    {
      data: FetchDriverTasksByDateAndStatusData,
      loading: FetchDriverTasksByDateAndStatusLoading,
      error: FetchDriverTasksByDateAndStatusError,
    },
  ] = useFetchDriverTasksByDateAndStatusLazyQuery();

  const [
    getDispatchPreviews,
    {
      data: dispatchPreviewApiData,
      loading: dispatchPreviewLoading,
      error: dispatchPreviewError,
      startPolling: dispatchPreviewStartPolling,
      stopPolling: dispatchPreviewStopPolling,
    },
  ] = useGetDispatchPreviewsLazyQuery();

  const [
    getDispatchPreviewsOffline,
    {
      data: dispatchPreviewApiDataOffline,
      loading: dispatchPreviewLoadingOffline,
      error: dispatchPreviewErrorOffline,
    },
  ] = useGetDispatchPreviewsLazyQuery();

  const [
    getDispatchPreviewsSpecialized,
    {
      data: dispatchPreviewApiDataSpecialized,
      loading: dispatchPreviewLoadingSpecialized,
      error: dispatchPreviewErrorSpecialized,
    },
  ] = useGetDispatchPreviewsLazyQuery();

  useEffect(() => {
    const error =
      FetchDriverTasksByDateAndStatusError ||
      dispatchPreviewError ||
      dispatchPreviewErrorOffline ||
      dispatchPreviewErrorSpecialized;
    if (error) {
      showErrorToast(error, showToast);
    }
  }, [
    FetchDriverTasksByDateAndStatusError,
    dispatchPreviewError,
    dispatchPreviewErrorOffline,
    dispatchPreviewErrorSpecialized,
  ]);

  return (
    <CandidatesGraphQLContext.Provider
      value={{
        FetchDriverTasksByDateAndStatus,
        FetchDriverTasksByDateAndStatusData,
        FetchDriverTasksByDateAndStatusLoading,
        FetchDriverTasksByDateAndStatusError,
        getDispatchPreviews,
        dispatchPreviewApiData,
        dispatchPreviewLoading,
        dispatchPreviewError,
        dispatchPreviewStartPolling,
        dispatchPreviewStopPolling,
        getDispatchPreviewsOffline,
        dispatchPreviewApiDataOffline,
        dispatchPreviewLoadingOffline,
        dispatchPreviewErrorOffline,
        getDispatchPreviewsSpecialized,
        dispatchPreviewApiDataSpecialized,
        dispatchPreviewLoadingSpecialized,
        dispatchPreviewErrorSpecialized,
      }}
    >
      {children}
    </CandidatesGraphQLContext.Provider>
  );
};

export const useCandidatesGraphQL = () => {
  const context = useContext(CandidatesGraphQLContext);
  if (context === undefined) {
    throw new Error(
      "useCandidatesGraphQL must be used within a CandidatesGraphQLProvider"
    );
  }
  return context;
};
