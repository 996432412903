import { useEffect, useState, useContext } from "react";
import {
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalHeader,
  Checkbox,
  Tooltip,
} from "@src/common/components";
import {
  ShipmentStopType,
  useForceCompleteShipmentMutation,
  useForceCompleteStopsMutation,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import StopCard from "@src/common/components/StopCard/StopCard";
import CourieButton from "@src/common/components/Button/Button";
import { getFirstTaskSequence } from "@src/shipments/utils/typeConvertors";
import { CheckIcon } from "@heroicons/react/24/outline";

interface ForceCompleteModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ForceCompleteModal = ({
  isModalOpen,
  onClose,
  onSuccess,
}: ForceCompleteModalProps) => {
  const { selectedRow } = useShipmentSelectionContext();
  const [selectedStopIds, setSelectedStopIds] = useState<string[]>([]);
  const { courieUser, courier, isEmployee, customClaims } =
    useContext(AuthContext);
  const { showToast } = useCourieStore();
  const courierId = courier?.courier?.id;
  const shipment = selectedRow?.shipment;
  const shipmentId = shipment?.id;
  const stops = shipment?.stops;
  const [
    forceCompleteShipment,
    {
      loading: forceCompleteShipmentLoading,
      error: forceCompleteShipmentError,
    },
  ] = useForceCompleteShipmentMutation();

  const [
    forceCompleteStops,
    { loading: forceCompleteStopsLoading, error: forceCompleteStopsError },
  ] = useForceCompleteStopsMutation();

  const loading = forceCompleteShipmentLoading || forceCompleteStopsLoading;

  useEffect(() => {
    if (forceCompleteShipmentError) {
      showErrorToast(forceCompleteShipmentError, showToast);
    }
    if (forceCompleteStopsError) {
      showErrorToast(forceCompleteStopsError, showToast);
    }
  }, [forceCompleteShipmentError, forceCompleteStopsError, showToast]);

  useEffect(() => {
    // Select all stops by default when modal opens
    if (isModalOpen && stops) {
      setSelectedStopIds(
        stops.filter((stop) => !stop.completedAt).map((stop) => stop.id)
      );
    }
  }, [isModalOpen, stops]);

  const handleToggleStop = (stopId: string) => {
    setSelectedStopIds((prev) =>
      prev.includes(stopId)
        ? prev.filter((id) => id !== stopId)
        : [...prev, stopId]
    );
  };

  const handleConfirm = async () => {
    if (!shipmentId || !stops || !courierId) return;

    const completedBy = isEmployee
      ? "EMPLOYEE"
      : courieUser?.id || customClaims?.courieUserId || undefined;

    try {
      if (selectedStopIds.length === stops.length) {
        await forceCompleteShipment({
          variables: {
            shipmentId,
            completedBy,
          },
        });
        showToast({
          message: "The order is force completed successfully",
          type: "success",
        });
      } else {
        await forceCompleteStops({
          variables: {
            courierId: courierId,
            shipmentId,
            stopIds: selectedStopIds,
            completedBy,
          },
        });
        showToast({
          message: "The selected stops were force completed successfully",
          type: "success",
        });
      }
      onSuccess();
      onClose();
    } catch (error) {
      // Errors are handled by the useEffect above
    }
  };

  if (!stops) return null;

  return (
    <BaseModal isOpen={isModalOpen} onClose={onClose} closeable size="default">
      <BaseModalHeader>Force Complete Stops</BaseModalHeader>
      <BaseModalBody>
        <p className="mb-4">Select the stops you want to force complete:</p>
        <div className="space-y-2">
          {stops
            .sort((a, b) => {
              const taskSequenceDifference =
                getFirstTaskSequence(a) - getFirstTaskSequence(b);
              if (a.type === ShipmentStopType.Return) return 1;
              if (b.type === ShipmentStopType.Return) return -1;
              if (taskSequenceDifference !== 0) return taskSequenceDifference;
              if (
                a.type === ShipmentStopType.PickUp &&
                b.type !== ShipmentStopType.PickUp
              )
                return -1;
              if (
                a.type !== ShipmentStopType.PickUp &&
                b.type === ShipmentStopType.PickUp
              )
                return 1;
              return 0;
            })
            .map((stop, i) => (
              <div key={stop.id} className="flex items-center">
                {stop.completedAt ? (
                  <Tooltip content="Completed">
                    <div className="w-4 h-4 bg-green-300 rounded-full">
                      <CheckIcon className="w-4 h-4 text-white" />
                    </div>
                  </Tooltip>
                ) : (
                  <Checkbox
                    checked={selectedStopIds.includes(stop.id)}
                    onChange={() => handleToggleStop(stop.id)}
                  />
                )}
                <StopCard stop={stop} />
              </div>
            ))}
        </div>
        {selectedStopIds.length === stops.length && (
          <p className="text-sm text-gray-500 text-center mt-2">
            Are you sure you want to complete this order on behalf of the driver
            without POD?
          </p>
        )}
      </BaseModalBody>
      <BaseModalFooter>
        <div className="flex justify-between border-t border-gray-200 pt-4">
          <CourieButton onClick={onClose} color="secondary">
            Cancel
          </CourieButton>
          <CourieButton
            onClick={handleConfirm}
            disabled={selectedStopIds.length === 0 || loading}
            isProcessing={loading}
            outline={false}
            color={"failure"}
          >
            Force Complete
          </CourieButton>
        </div>
      </BaseModalFooter>
    </BaseModal>
  );
};

export default ForceCompleteModal;
